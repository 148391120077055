import React, { useEffect, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"

import Section from "../../components/Section/Section"

import "./About.scss"
import LogoAboutus from "../../images/about/sp/logo-aboutus"
import LogoAnincoSP from "../../images/about/sp/logo-aninco"
import LogoAnincoPC from "../../images/about/pc/logo-aninco"
import LogoInstagramSP from "../../images/about/sp/icon-instagram"
import LogoInstagramPC from "../../images/about/pc/icon-instagram"

const About = props => {
  /** デバイス */
  const [device, setDevice] = useState("sp")

  const changePath = () => {
    setDevice(document.body.clientWidth > 990 ? "pc" : "sp")
  }

  useEffect(() => {
    changePath()

    window.addEventListener("resize", changePath())
  }, [device])

  return (
    <Section id="About" className={`About About--${device}`}>
      <div className="About__inner">
        <div className="About__instagram">
          {device === "sp" ? (
            <div class="About__instagramImage">
              <LogoInstagramSP
                width={17}
                height={17}
              />
            </div>
          ) : (
            <div class="About__instagramImage">
              <LogoInstagramPC
                width={272}
                height={272}
              />
            </div>
          )}
          {device === "sp" ? (
            <div className="About__instagramAbout">
              <LogoAboutus width="71" height="14" />
            </div>
          ) : (
            <div className="About__instagramAbout">
              <LogoAboutus width="96" height="19.19" />
            </div>
          )}
        </div>
        <div className="About__logo">
          {device === "sp" ? (
            <LogoAnincoSP
              width={212.6}
              height={43.199}
            />
          ) : (
            <LogoAnincoPC />
          )}
        </div>
        {device === "sp" ? (
          <p className="About__summary">
            株式会社ANINは、ソーシャルメディアを中心とした広告掲載・コンサルティング・運用代行・インスタグラマーの成長支援を行っている会社です。自社メディアの構築にて培ったノウハウと、マーケティング戦略を活かしたコンセプト策定、市場調査からクリエイティブ作成まですべてを手がけています。
            <br /><br />
            誰もが自分の一番ありたい姿で輝くことができる「ありのままの世界」を創ることが私たちの目指すべき場所です。
          </p>
        ) : (
          <p className="About__summary">
            株式会社
            <ruby>
              <rb>ANIN</rb>
              <rp>（</rp>
              <rt>あんにん</rt>
              <rp>）</rp>
            </ruby>
            は、ソーシャルメディアを中心とした
            <br />
            広告掲載・コンサルティング・運用代行・インスタグラマーの成長支援を行っている会社です。
            <br />
            自社メディアの構築にて培ったノウハウと、マーケティング戦略を活かしたコンセプト策定、
            <br />
            市場調査からクリエイティブ作成まですべてを手がけています。
            <br />
            <br />
            誰もが自分の一番ありたい姿で輝くことができる「ありのままの世界」を創ることが
            <br />
            私たちの目指すべき場所です。
          </p>
        )}
      </div>
    </Section>
  )
}

export default About
