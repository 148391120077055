import React from "react"

import Seo from "../components/seo"

import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import FadeIn from "../components/FadeIn/FadeIn"
import Main from "../modules/Main/Main"
import About from "../modules/About/About"
import Service from "../modules/Service/Service"
import Greeding from "../modules/Greeding/Greeding"
import Contact from "../modules/Contact/Contact"
import Company from "../modules/Company/Company"
import CaseStudy from "../modules/CaseStudy/CaseStudy"

import "../assets/reset.css"
import "../assets/base.scss"

const IndexPage = () => (
  <>
    <Seo
      title='ANIN Co. | 誰もが輝くことの出来る "ありのままの世界" を創る'
      ogImage="https://anin-sns.com/images/ogp/img-ogp.jpg"
    />
    <Header />
    <Main />
    <About />
    <Service />
    <CaseStudy />
    <Greeding />
    <Contact />
    <Company />
    <Footer />
    <FadeIn />
  </>
)

export default IndexPage
