import React, { useEffect, useState } from "react"

import Section from "../../components/Section/Section"
import ButtonLink from "../../components/Button/ButtonLink"
import ScrollBar from "../../components/ScrollBar/ScrollBar"
import Star from "../../components/Star/Star"

import "./Main.scss"

const Main = props => {
  /** デバイス */
  const [device, setDevice] = useState("sp")

  const scrollNavigation = id => {
    const target = document.querySelector(`#${id}`)
    const titleHeight = device === "pc" ? 110 : 60
    const targetY =
      target.getBoundingClientRect().top + window.pageYOffset - titleHeight
    window.scrollTo({ top: targetY, left: 0, behavior: "smooth" })
  }

  const changePath = () => {
    setDevice(document.body.clientWidth > 990 ? "pc" : "sp")
  }

  useEffect(() => {
    changePath()
    window.addEventListener("resize", changePath())
  }, [device])

  return (
    <Section id="Main" className={`Main Main--${device}`}>
      <main className="Main__main">
        <div className="Main__item Main__main--message">
          <img
            src={`${process.env.IMAGE_PATH}main/${device}/img-message.webp`}
            alt="誰もが輝くことが出来るありのままの世界を創る"
            width={308}
            className="Main__message"
          />
          {device === "pc" && (
            <ButtonLink
              onClick={() => scrollNavigation("Contact")}
              text="お問い合わせ・お見積り"
              color="red"
              backdrop
              arrow
            />
          )}
        </div>

        <div className="Main__item Main__item--women">
          {/* 背景の星アニメーション */}
          {/* <div className="Main__starWrapper"> */}
          {device === "pc" && <Star />}
          {/* </div> */}
          <img
            src={`${process.env.IMAGE_PATH}main/${device}/img-womenIllustration.webp`}
            alt=""
            className="Main__women"
          />
        </div>

        <ScrollBar />
      </main>
      {/* 背景の星アニメーション */}
      {device === "sp" && (
        <div className="Main__starWrapper">
          <Star />
        </div>
      )}
      <style jsx global>{`
        .Main {
          background-image: url(${process.env
            .IMAGE_PATH}main/${device}/bg-main.webp);
        }
      `}</style>
    </Section>
  )
}

export default Main
