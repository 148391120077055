import React, { useEffect, useState } from "react"

import Title from "../../components/Title/Title"
import TitleList from "../../components/Title/TitleList"
import Section from "../../components/Section/Section"
import Badge from "../../components/Badge/Badge"
import Card from "../../components/Card/Card"
import ButtonLink from "../../components/Button/ButtonLink"
import Strengths from "../../components/Strengths/Strengths"
import NumberList from "../../components/List/NumberList"

import IconAdSP from "../../images/service/sp/icon-ad"
import IconInstagramSP from "../../images/service/sp/icon-instagram"
import IconConsultingSP from "../../images/service/sp/icon-consulting"
import IconBreedingSP from "../../images/service/sp/icon-breeding"
import IconAdPC from "../../images/service/pc/icon-ad"
import IconInstagramPC from "../../images/service/pc/icon-instagram"
import IconConsultingPC from "../../images/service/pc/icon-consulting"
import IconBreedingPC from "../../images/service/pc/icon-breeding"

import "./Service.scss"
import IconTouch from "../../images/service/sp/icon-touch"

const Service = props => {
  /** デバイス */
  const [device, setDevice] = useState("sp")

  const scrollNavigation = id => {
    const target = document.querySelector(`#${id}`)
    const titleHeight = device === "pc" ? 110 : 60
    const targetY =
      target.getBoundingClientRect().top + window.pageYOffset - titleHeight
    window.scrollTo({ top: targetY, left: 0, behavior: "smooth" })
  }

  const changePath = () => {
    setDevice(document.body.clientWidth > 990 ? "pc" : "sp")
  }

  useEffect(() => {
    changePath()
    window.addEventListener("resize", changePath())
  }, [device])

  return (
    <Section id="Service" className={`Service Service--${device}`}>
      <Title title="Service" text="サービス" />
      <div className="Service__grid">
        <ul>
          <li className="Service__gridItem">
            <div class="Service__icon">
              {device === "sp" ? <IconAdSP /> : <IconAdPC />}
            </div>
            <span>広告</span>
          </li>
          {device === "sp" ? (
            <li className="Service__gridItem">
              <div class="Service__icon">
                <IconInstagramSP width={41} height={41} />
              </div>
              <span>SNS運用代行</span>
            </li>
          ) : (
            <li className="Service__gridItem">
              <div class="Service__icon">
                <IconConsultingPC />
              </div>
              <span>SNSコンサルティング</span>
            </li>
          )}
          {device === "sp" ? (
            <li className="Service__gridItem">
              <div class="Service__icon">
                <IconConsultingSP />
              </div>
              <span>SNSコンサルティング</span>
            </li>
          ) : (
            <li className="Service__gridItem">
              <div class="Service__icon">
                <IconInstagramPC />
              </div>
              <span>SNS運用代行</span>
            </li>
          )}
          <li className="Service__gridItem">
            <div className="Service__icon">
              {device === "sp" ? <IconBreedingSP /> : <IconBreedingPC />}
            </div>
            <span>インスタグラマー育成</span>
          </li>
        </ul>
      </div>

      <Card addClassName="Service__card Service__card--1">
        <Badge
          text="1"
          size="large"
          color="red"
          addClassName="Service__cardBadge"
        />
        <div className="Service__cardInner Service__cardInner--end">
          <div className="Service__cardInnerItem">
            <div className="Service__cardTitleWrapper">
              <TitleList
                title="ADVERTISING"
                text={device === "sp" ? "広告掲載事業" : "広告事業"}
              />
              {device === "sp" && (
                <div className="Service__tileLinkWrapper">
                  <a
                    href="https://instagram.com/anin.beautyyy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="Service__tileLink"
                  >
                    <IconInstagramSP width={24} height={24} />
                    <span>アカウント</span>
                  </a>
                  <div className="Service__tileLinkTouch">
                    <IconTouch />
                  </div>
                </div>
              )}
            </div>
            {device === "sp" && (
              <img
                src={`${process.env.IMAGE_PATH}service/sp/img-instagram.webp`}
                alt="インスタグラムイメージ"
                loading="lazy"
                className="Service__instagramImage"
              />
            )}
            <p className="Service__cardSummary Service__cardSummary--red">
              当社管理の自社アカウントにてクライアントの認知獲得や
              <br />
              集客のための投稿を作成、発信いたします！
            </p>
            {device === "pc" && (
              <span className="Service__badge">
                毎月3,000,000人以上にリーチ
              </span>
            )}
            <ul className="Service__cardList">
              <li>純広告（1フィード投稿、1ストーリー）</li>
              <li>成果報酬型広告</li>
            </ul>
            <p className="Service__cardSummary">
              など目的に合わせた商品、サービスのPRをいたします。
            </p>
            <div className="Service__buttonLinkWrapper">
              <ButtonLink
                href={`${process.env.ANIN_PATH}/pdf/advertising.pdf`}
                target
                text="料金表はこちら"
                color="blue"
                arrow={device === "pc"}
              />
              {device === "sp" && (
                <ButtonLink
                  onClick={() => scrollNavigation("Contact")}
                  text="お問い合わせ"
                  color="red"
                />
              )}
            </div>
          </div>
          {device === "pc" && (
            <div className="Service__cardInnerItem">
              <img
                src={`${process.env.IMAGE_PATH}service/pc/img-instagram.webp`}
                width={676}
                alt="インスタグラムイメージ"
                loading="lazy"
                className="Service__instagramImage"
              />
            </div>
          )}
        </div>
      </Card>

      <Card addClassName="Service__card Service__card--2">
        <Badge
          text="2"
          size="large"
          color="red"
          addClassName="Service__cardBadge"
        />
        <div className="Service__cardInner Service__cardInner--center">
          <div className="Service__cardInnerItem">
            <div className="Service__cardTitleWrapper">
              <TitleList
                title="CONSULTING"
                text="コンサルティング・運用代行事業"
              />
              {device === "pc" && (
                <div className="Service__cardInnerItem">
                  <div className="Service__buttonLinkWrapper">
                    <ButtonLink
                      href={`${process.env.ANIN_PATH}/pdf/consulting.pdf`}
                      target
                      text="料金表はこちら"
                      color="blue"
                      arrow={device === "pc"}
                    />
                  </div>
                </div>
              )}
            </div>
            <p className="Service__cardSummary Service__cardSummary--red">
              複数のアカウントをゼロから設立し、
              <br />
              たった3ヶ月間でフォロワー10,000人超に育成した経験と実績を活かし、インスタの運用支援を行います。
            </p>
          </div>
        </div>

        <div className="Service__cardStrengths">
          <Strengths
            title="新規アカウントの育成"
            items={["マーケティング", "コンセプトメイキング"]}
          />
          <Strengths
            title="既存アカウントの育成"
            items={["運用指導", "月次で定例MTGに参加", "クリエイティブ修正"]}
          />
          <Strengths
            title="アカウント運用代行"
            items={["マーケティング", "コンセプトメイキング"]}
          />
        </div>
        {device === "sp" && (
          <div className="Service__buttonLinkWrapper">
            <ButtonLink
              href={`${process.env.ANIN_PATH}/pdf/consulting.pdf`}
              target
              text="料金表はこちら"
              color="blue"
            />
            <ButtonLink
              onClick={() => scrollNavigation("Contact")}
              text="お問い合わせ"
              color="red"
            />
          </div>
        )}
      </Card>

      <Card addClassName="Service__card Service__card--3">
        <Badge
          text="3"
          size="large"
          color="red"
          addClassName="Service__cardBadge"
        />
        <div className="Service__cardInner">
          <div className="Service__cardInnerItem">
            {device === "sp" && (
              <p className="Service__teachingImageWrapper">
                <img
                  src={`${process.env.IMAGE_PATH}service/sp/img-womenIllustration.webp`}
                  alt=""
                  loading="lazy"
                  className=""
                />
              </p>
            )}
            <div className="Service__cardTitleWrapper">
              <TitleList title="TEACHING" text="インスタグラマー育成事業" />
            </div>
            {device === "sp" && (
              <React.Fragment>
                <p className="Service__cardSummary Service__cardSummary--red">
                  複数のアカウントをゼロから設立し、
                  たった3ヶ月間でフォロワー10,000人超に育成した経験と実績を活かし、インスタの運用支援を行います。
                </p>

                <NumberList
                  items={[
                    "マネタイズ支援",
                    "アカウント作成コンサルティング",
                    "アカウントコンセプト作成・修正",
                    "市場分析サポート",
                    "Instagramの基礎知識",
                    "バズるデザインフレームの作成",
                  ]}
                />
              </React.Fragment>
            )}

            {device === "pc" && (
              <ul className="Service__cardUnit">
                <li>マネタイズ支援</li>
                <li>アカウント作成コンサルティング</li>
                <li>アカウントコンセプト作成・修正</li>
                <li>市場分析サポート</li>
                <li>Instagramの基礎知識</li>
                <li>バズるデザインフレームの作成</li>
              </ul>
            )}

            <div className="Service__buttonLinkWrapper">
              <ButtonLink
                href={`${process.env.ANIN_PATH}/pdf/teaching.pdf`}
                target
                text="料金表はこちら"
                color="blue"
                arrow={device === "pc"}
              />
              {device === "sp" && (
                <ButtonLink
                  onClick={() => scrollNavigation("Contact")}
                  text="お問い合わせ"
                  color="red"
                />
              )}
            </div>
          </div>
          {device === "pc" && (
            <div className="Service__cardInnerItem">
              <img
                src={`${process.env.IMAGE_PATH}service/pc/img-womenIllustration.webp`}
                alt=""
                width={872}
                loading="lazy"
                className="Service__cardPCWomenImage"
              />
            </div>
          )}
        </div>
      </Card>
    </Section>
  )
}

export default Service
