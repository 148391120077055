import { string } from "prop-types"
import React from "react"

import "./FormLabel.scss"

const FormLabel = props => (
  <div className="FormLabel">
    <label htmlFor={props.id} className="FormLabel__title">
      <span className="FormLabel__titleText">{props.title}</span>
      {props.label && (
        <span
          className={`FormLabel__badge${
            props.label === "必須" ? " FormLabel__badge--required" : ""
          }`}
        >
          {props.label}
        </span>
      )}
    </label>
  </div>
)

FormLabel.prototype = {
  title: string,
  label: "任意" | "必須",
  /** 紐付けるFormのid */
  id: string,
}

export default FormLabel
