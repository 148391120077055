import React from "react"

import "./Card.scss"

const Card = props => (
  <div className={`Card${props.addClassName ? ` ${props.addClassName}` : ""}`}>
    {props.children}
  </div>
)

export default Card
