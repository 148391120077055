// 企業様向け
import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { init, send } from 'emailjs-com';

import FormLabel from "../../components/Form/FormLabel"
import Input from "../../components/Form/Input"
import Select from "../../components/Form/Select"
import Textarea from "../../components/Form/Textarea"
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch.js"

const Corporation = props => {
  /** 企業名または屋号 */
  const [companyName, setCompanyName] = useState("")
  /** 企業名または屋号のふりがな */
  const [companyNameRuby, setCompanyNameRuby] = useState("")
  /** 担当者名 */
  const [manager, setManager] = useState("")
  /** 担当者名のふりがな */
  const [managerRuby, setManagerRuby] = useState("")
  /** 部署名 */
  const [department, setDepartment] = useState("")
  /** 役職 */
  const [position, setPosition] = useState("")
  /** 予算 */
  const [budget, setBudget] = useState("")
  /** 電話番号 */
  const [tel, setTel] = useState("")
  /** メールアドレス */
  const [email, setEMail] = useState("")
  /** お問い合わせ種別 */
  const [inquiry, setInquiry] = useState("運用代行")
  /** お問い合わせ内容 */
  const [inquiryContents, setInquiryContents] = useState("")
  /** 利用規約/プライバs−ポリシーにどういしたか */
  const [agree, setAgree] = useState(false)
  /** 送信中 */
  const [isSending, setIsSending] = useState(false)
  /** デバイス */
  const [device, setDevice] = useState("sp")

  /**
   * @param type : フォームアイテムの固有名
   * @param value : フォームアイテム要素が変更した内容
   */

  const changeForm = (type, value) => {
    if (type === "companyName") {
      setCompanyName(value)
    } else if (type === "companyNameRuby") {
      setCompanyNameRuby(value)
    } else if (type === "manager") {
      setManager(value)
    } else if (type === "managerRuby") {
      setManagerRuby(value)
    } else if (type === "department") {
      setDepartment(value)
    } else if (type === "position") {
      setPosition(value)
    } else if (type === "budget") {
      setBudget(value)
    } else if (type === "tel") {
      setTel(value)
    } else if (type === "email") {
      setEMail(value)
    } else if (type === "inquiry") {
      setInquiry(value)
    } else if (type === "inquiryContents") {
      setInquiryContents(value)
    }
  }

  const toggleForm = value => {
    setAgree(value)
  }

  const changePath = () => {
    setDevice(document.body.clientWidth > 990 ? "pc" : "sp")
  }

  useEffect(() => {
    changePath()

    window.addEventListener("resize", changePath())
  }, [device])

  /** メール送信 */
  const sendMail = () => {
    const userID = process.env.REACT_APP_USER_ID;
    const serviceID = process.env.REACT_APP_SERVICE_ID;
    const templateID = process.env.REACT_APP_TEMPLATE_ID_CORPORATION;

    if (
      userID !== undefined &&
      serviceID !== undefined &&
      templateID !== undefined
    ) {
      init(userID);
    };

    const template_param = {
      companyName: companyName,
      companyNameRuby: companyNameRuby,
      manager: manager,
      managerRuby: managerRuby,
      department: department,
      position: position,
      budget: budget,
      tel: tel,
      email: email,
      inquiry: inquiry,
      inquiryContents: inquiryContents,
    };

    send(serviceID, templateID, template_param).then(() => {
      window.alert('お問い合わせを送信致しました。');

      setCompanyName('');
      setCompanyNameRuby('');
      setManager('');
      setManagerRuby('');
      setDepartment('');
      setPosition('');
      setBudget('');
      setTel('');
      setEMail('');
      setInquiry('運用代行');
      setInquiryContents('');
      setAgree(false);
      setIsSending(false);
    });
  }

  const handleClick = (e) => {
    e.preventDefault();
    setIsSending(true);
    sendMail();
  };

  /** 必須項目の入力判定 */
  const disableSend = companyName === ""
    || companyNameRuby === ""
    || manager === ""
    || managerRuby === ""
    || inquiry === ""
    || tel === ""
    || email === ""
    || inquiryContents === "";

  return (
    <div className="Tab__panel">
      <div className="Contact__formItem">
        <FormLabel title="企業名または屋号" label="必須" id="companyName" />
        <Input
          type="text"
          placeholder="企業名または屋号を入力してください。"
          name="companyName"
          id="companyName"
          required={true}
          value={companyName}
          onChange={e => changeForm("companyName", e.target.value)}
          autocomplete="organization"
        />
      </div>

      <div className="Contact__formItem">
        <FormLabel title="ふりがな" label="必須" id="companyNameRuby" />
        <Input
          type="text"
          placeholder="ふりがなを入力してください。"
          name="companyNameRuby"
          id="companyNameRuby"
          required={true}
          value={companyNameRuby}
          onChange={e => changeForm("companyNameRuby", e.target.value)}
        />
      </div>

      <div className="Contact__formItem">
        <FormLabel title="担当者名" label="必須" id="manager" />
        <Input
          type="text"
          placeholder="担当者名を入力してください。"
          name="manager"
          id="manager"
          required={true}
          value={manager}
          onChange={e => changeForm("manager", e.target.value)}
          autocomplete="name"
        />
      </div>

      <div className="Contact__formItem">
        <FormLabel title="ふりがな" label="必須" id="managerRuby" />
        <Input
          type="text"
          placeholder="ふりがなを入力してください。"
          name="managerRuby"
          id="managerRuby"
          required={true}
          value={managerRuby}
          onChange={e => changeForm("managerRuby", e.target.value)}
        />
      </div>

      <div className="Contact__formItem">
        <FormLabel title="部署" label="任意" id="department" />
        <Input
          type="text"
          placeholder="部署を入力してください。"
          name="department"
          id="department"
          value={department}
          onChange={e => changeForm("department", e.target.value)}
        />
      </div>

      <div className="Contact__formItem">
        <FormLabel title="役職" label="任意" id="position" />
        <Input
          type="text"
          placeholder="役職を入力してください。"
          name="position"
          id="position"
          value={position}
          onChange={e => changeForm("position", e.target.value)}
        />
      </div>

      <div className="Contact__formItem">
        <FormLabel title="お問い合わせ種別" label="必須" id="inquiry" />
        <Select
          id="inquiry"
          name="inquiry"
          placeholder="お問い合わせ種別を選択してください。"
          options={[
            { text: "運用代行", value: "運用代行" },
            { text: "コンサルティング", value: "コンサルティング" },
            { text: "広告のご依頼", value: "広告のご依頼" },
            { text: "その他", value: "その他" },
          ]}
          required
          value={inquiry}
          onChange={e => changeForm("inquiry", e.target.value)}
        />
      </div>

      <div className="Contact__formItem">
        <FormLabel title="予算" label="任意" id="budget" />
        <Input
          type="text"
          placeholder="予算を入力してください。"
          name="budget"
          id="budget"
          value={budget}
          onChange={e => changeForm("budget", e.target.value)}
        />
      </div>

      <div className="Contact__formItem">
        <FormLabel title="電話番号" label="必須" id="tel" />
        <Input
          type="tel"
          placeholder="電話番号を入力してください。"
          name="tel"
          id="tel"
          required={true}
          value={tel}
          onChange={e => changeForm("tel", e.target.value)}
        />
      </div>

      <div className="Contact__formItem">
        <FormLabel title="メールアドレス" label="必須" id="email" />
        <Input
          type="email"
          placeholder="メールアドレスを入力してください。"
          name="email"
          id="email"
          required={true}
          value={email}
          onChange={e => changeForm("email", e.target.value)}
          autocomplete="email"
        />
      </div>

      <div className="Contact__formItem">
        <FormLabel
          title="お問い合わせ内容"
          label="必須"
          id="inquiryContents"
        />
        <Textarea
          id="inquiryContents"
          name="inquiryContents"
          placeholder="お問い合わせ内容を入力してください。（最大2000文字）"
          value={inquiryContents}
          onChange={e => changeForm("inquiryContents", e.target.value)}
        />
      </div>

      <div className="Contact__formItem Contact__formItem--terms">
        {device === "pc" && (
          <div className="Contact__termsCheckBox">
            <input
              type="checkbox"
              name="terms"
              required
              value={agree}
              checked={agree}
              onChange={e => toggleForm(e.target.checked)}
            />
          </div>
        )}

        <p className="Contact__termsConfirm">
          {/* <a href="#dummy">ご利用規約</a>・ */}
          <Link to="/privacy/">プライバシーポリシー</Link>に同意する
        </p>

        {device === "sp" && (
          <ToggleSwitch
            value={agree}
            required
            onChange={e => toggleForm(e.target.checked)}
          />
        )}
      </div>

      <div className="Contact__formSubmit">
        <button
          className="Contact__formSubmitButton"
          onClick={handleClick}
          disabled={disableSend || !agree || isSending}
        >
          {isSending ? "送信中" : "送信する"}
        </button>
      </div>
    </div>
  )
}

export default Corporation
