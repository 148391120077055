import React from "react"

import "./TitleLabel.scss"

const TitleLabel = props => (
  <div className="TitleLabel">
    <span className="TitleLabel__text">{props.text}</span>
  </div>
)

export default TitleLabel
