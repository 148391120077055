import React from "react"
import { string } from "prop-types"
import { StaticImage } from "gatsby-plugin-image"

import "./Select.scss"

const Select = props => (
  <div className="Select">
    <select
      id={props.id}
      name={props.name}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      required={props.required}
      className="Select__select"
    >
      {props.options.map((item, index) => (
        <option key={index} value={item.value}>
          {item.text}
        </option>
      ))}
    </select>
    <i>
      <StaticImage
        src="../../images/contact/pc/icon-triangle.svg"
        alt=""
        width={15}
        height={13}
        placeholder="none"
      />
    </i>
  </div>
)

Select.prototype = {
  placeholder: string,
  name: string,
  /** labelと紐付けるFormのid */
  id: string,
}

export default Select
