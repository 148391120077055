import { string } from "prop-types"
import React from "react"

const LogoLineOA = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 574.249 44.157">
    <defs>
      <clipPath id="a">
        <path fill="#06c755" d="M0 0h574.249v44.157H0z" data-name="長方形 5356"/>
      </clipPath>
    </defs>
    <g fill="#06c755" clip-path="url(#a)" data-name="グループ 6030">
      <title>LINE Official Account</title>
      <path d="M39.436 3.361h-5.9a1.638 1.638 0 0 0-1.642 1.64v36.652a1.638 1.638 0 0 0 1.639 1.637h5.9a1.638 1.638 0 0 0 1.639-1.637V5.001a1.638 1.638 0 0 0-1.636-1.64" data-name="パス 17054"/>
      <path d="M80.054 3.361h-5.9a1.638 1.638 0 0 0-1.64 1.64v21.773L55.716 4.089a1.846 1.846 0 0 0-.13-.166l-.01-.011c-.032-.034-.063-.069-.1-.1l-.031-.029c-.029-.025-.057-.05-.088-.073l-.042-.032c-.029-.021-.055-.04-.086-.059l-.05-.031a1.825 1.825 0 0 0-.088-.048l-.052-.025a.811.811 0 0 0-.094-.04l-.045-.019c-.032-.011-.065-.023-.1-.032l-.057-.013a1.165 1.165 0 0 0-.1-.021l-.069-.011c-.029 0-.057-.008-.086-.01s-.057 0-.086-.006h-5.955a1.638 1.638 0 0 0-1.634 1.638v36.652a1.638 1.638 0 0 0 1.639 1.637h5.9a1.638 1.638 0 0 0 1.639-1.637V19.882l16.821 22.719a1.574 1.574 0 0 0 .414.4l.017.013.1.061.048.027c.025.013.052.025.076.036l.08.034.05.019c.036.013.074.025.111.034l.023.006a1.616 1.616 0 0 0 .42.057h5.9a1.638 1.638 0 0 0 1.639-1.637V5.001a1.638 1.638 0 0 0-1.636-1.64" data-name="パス 17055"/>
      <path d="M25.212 34.11H9.18V4.996a1.638 1.638 0 0 0-1.64-1.635h-5.9A1.636 1.636 0 0 0 0 4.996v36.657a1.626 1.626 0 0 0 .458 1.131l.021.025.021.022a1.629 1.629 0 0 0 1.132.456h23.58a1.638 1.638 0 0 0 1.637-1.639v-5.9a1.638 1.638 0 0 0-1.637-1.639" data-name="パス 17056"/>
      <path d="M112.641 3.361H89.066a1.62 1.62 0 0 0-1.136.462l-.021.017-.025.029a1.63 1.63 0 0 0-.455 1.132v36.652a1.631 1.631 0 0 0 .458 1.132l.021.025.025.021a1.631 1.631 0 0 0 1.132.458h23.576a1.638 1.638 0 0 0 1.637-1.638v-5.9a1.639 1.639 0 0 0-1.637-1.64H96.609v-6.2h16.032a1.638 1.638 0 0 0 1.637-1.639v-5.9a1.638 1.638 0 0 0-1.637-1.639H96.609v-6.2h16.032a1.638 1.638 0 0 0 1.637-1.632v-5.9a1.638 1.638 0 0 0-1.637-1.64" data-name="パス 17057"/>
      <path d="M387.99 43.469h-7.744a1.332 1.332 0 0 1-1.037-.378 1.97 1.97 0 0 1-.474-.88l-2.459-6.736h-15.861l-2.393 6.736a2.987 2.987 0 0 1-.5.88 1.236 1.236 0 0 1-1.006.378h-7.37q-1.26 0-.819-1.2l14.668-37.643a2.306 2.306 0 0 1 .724-.945 2.384 2.384 0 0 1 1.543-.439h6.674a2.279 2.279 0 0 1 1.48.439 2.945 2.945 0 0 1 .785.945l14.675 37.648c.292.8 0 1.2-.882 1.2m-14.736-16.373-4.91-13.536-4.912 13.536h9.822Z" data-name="パス 17058"/>
      <path d="M411.414 34.289a1.949 1.949 0 0 1 1.155-.281 1.52 1.52 0 0 1 1.155.718l2.561 3.81a1.4 1.4 0 0 1 .248 1.249 1.891 1.891 0 0 1-.686 1 17.028 17.028 0 0 1-4.5 2.341 15.806 15.806 0 0 1-5.307.905 15.268 15.268 0 0 1-6.082-1.219 15.862 15.862 0 0 1-8.336-8.179 14.378 14.378 0 0 1-1.249-5.964 15.135 15.135 0 0 1 1.218-6.025 15.446 15.446 0 0 1 8.212-8.244 14.915 14.915 0 0 1 5.991-1.221 16.76 16.76 0 0 1 5.62.907 15.188 15.188 0 0 1 4.559 2.528 1.9 1.9 0 0 1 .594 1.029 1.454 1.454 0 0 1-.344 1.281l-2.811 3.5a1.31 1.31 0 0 1-1.123.655 2.663 2.663 0 0 1-1.186-.342 8.588 8.588 0 0 0-2.219-1.031 9.063 9.063 0 0 0-2.59-.344 6.853 6.853 0 0 0-5.152 2.03 7.26 7.26 0 0 0-1.967 5.277 7.006 7.006 0 0 0 2 5.183 6.931 6.931 0 0 0 5.122 2 8.632 8.632 0 0 0 2.685-.4 10.8 10.8 0 0 0 2.435-1.155" data-name="パス 17059"/>
      <path d="M440.115 34.289a1.949 1.949 0 0 1 1.155-.281 1.514 1.514 0 0 1 1.153.718l2.563 3.81a1.4 1.4 0 0 1 .248 1.249 1.884 1.884 0 0 1-.687 1 17.016 17.016 0 0 1-4.5 2.341 15.806 15.806 0 0 1-5.307.905 15.252 15.252 0 0 1-6.088-1.218 15.857 15.857 0 0 1-8.338-8.179 14.379 14.379 0 0 1-1.249-5.964 15.135 15.135 0 0 1 1.218-6.025 15.446 15.446 0 0 1 8.217-8.246 14.915 14.915 0 0 1 5.995-1.22 16.76 16.76 0 0 1 5.62.907 15.187 15.187 0 0 1 4.559 2.528 1.9 1.9 0 0 1 .594 1.029 1.45 1.45 0 0 1-.346 1.281l-2.809 3.5a1.305 1.305 0 0 1-1.123.655 2.67 2.67 0 0 1-1.188-.342 8.577 8.577 0 0 0-2.217-1.031 9.063 9.063 0 0 0-2.59-.344 6.856 6.856 0 0 0-5.152 2.03 7.268 7.268 0 0 0-1.967 5.277 7.006 7.006 0 0 0 2 5.183 6.933 6.933 0 0 0 5.122 2 8.611 8.611 0 0 0 2.683-.4 10.811 10.811 0 0 0 2.437-1.155" data-name="パス 17060"/>
      <path d="M463.496 12.991a15.291 15.291 0 0 1 6.088 1.218 15.712 15.712 0 0 1 4.965 3.34 16.151 16.151 0 0 1 3.373 4.965 14.964 14.964 0 0 1 1.249 6.088 14.814 14.814 0 0 1-1.249 6.027 16.119 16.119 0 0 1-3.373 4.963 15.654 15.654 0 0 1-4.965 3.342 15.843 15.843 0 0 1-12.176 0 15.6 15.6 0 0 1-8.305-8.305 15.144 15.144 0 0 1-1.218-6.027 15.3 15.3 0 0 1 1.218-6.088 15.581 15.581 0 0 1 8.305-8.305 15.285 15.285 0 0 1 6.088-1.218m0 22.731a6.4 6.4 0 0 0 4.715-2.03 6.9 6.9 0 0 0 1.406-2.278 7.852 7.852 0 0 0 .5-2.811 8.03 8.03 0 0 0-.5-2.872 6.9 6.9 0 0 0-1.406-2.278 6.571 6.571 0 0 0-7.337-1.5 6.082 6.082 0 0 0-2.061 1.5 7.156 7.156 0 0 0-1.375 2.278 7.988 7.988 0 0 0-.5 2.872 7.809 7.809 0 0 0 .5 2.811 7.156 7.156 0 0 0 1.375 2.278 6.082 6.082 0 0 0 2.061 1.5 6.358 6.358 0 0 0 2.622.531" data-name="パス 17061"/>
      <path d="M512.017 29.981a15.509 15.509 0 0 1-1 5.683 12.009 12.009 0 0 1-2.842 4.339 12.6 12.6 0 0 1-4.465 2.748 16.66 16.66 0 0 1-5.807.968 16.854 16.854 0 0 1-5.871-.968 12.612 12.612 0 0 1-4.465-2.748 12.009 12.009 0 0 1-2.842-4.339 15.548 15.548 0 0 1-1-5.683V15.18a1.6 1.6 0 0 1 1.626-1.622h5.62a1.6 1.6 0 0 1 1.625 1.623v14.051a6.358 6.358 0 0 0 1.467 4.432 4.931 4.931 0 0 0 3.84 1.623 4.839 4.839 0 0 0 3.81-1.623 6.456 6.456 0 0 0 1.436-4.432V15.18a1.6 1.6 0 0 1 1.624-1.622h5.62a1.6 1.6 0 0 1 1.623 1.623Z" data-name="パス 17062"/>
      <path d="M545.544 43.001a1.571 1.571 0 0 1-1.155.468h-5.807a1.6 1.6 0 0 1-1.623-1.623v-14.8a5.588 5.588 0 0 0-1.343-3.871 4.515 4.515 0 0 0-3.529-1.5 4.664 4.664 0 0 0-1.4.25 5.16 5.16 0 0 0-1.469.749A4.954 4.954 0 0 0 528 23.986a3.961 3.961 0 0 0-.592 2v15.86a1.6 1.6 0 0 1-1.625 1.623h-6.056a1.6 1.6 0 0 1-1.625-1.623V15.307a1.6 1.6 0 0 1 1.62-1.627h6.056a1.6 1.6 0 0 1 1.625 1.627v3.247a7.53 7.53 0 0 1 3.247-3.965 9.019 9.019 0 0 1 4.745-1.409 9.669 9.669 0 0 1 7.775 3.34q2.842 3.343 2.842 9.149v16.177a1.571 1.571 0 0 1-.468 1.155" data-name="パス 17063"/>
      <path d="M574.213 40.41a1.488 1.488 0 0 1-.5.749 13.5 13.5 0 0 1-3.651 1.936 12.582 12.582 0 0 1-4.278.749q-5 0-7.713-3.122t-2.716-8.867V21.302h-4a1.518 1.518 0 0 1-1.155-.5 1.676 1.676 0 0 1-.468-1.186v-4.309a1.684 1.684 0 0 1 .468-1.188 1.523 1.523 0 0 1 1.155-.5h4.06V7.75a1.6 1.6 0 0 1 1.623-1.623h5.62a1.6 1.6 0 0 1 1.623 1.623v5.869h5.993a1.521 1.521 0 0 1 1.155.5 1.676 1.676 0 0 1 .468 1.188v4.312a1.668 1.668 0 0 1-.468 1.186 1.516 1.516 0 0 1-1.155.5h-5.871v10.3q0 4 3.122 4a3.894 3.894 0 0 0 1.281-.187 5.065 5.065 0 0 0 1.029-.5 1.307 1.307 0 0 1 .968-.218 1.2 1.2 0 0 1 .781.594l2.435 4.121a1.306 1.306 0 0 1 .187 1" data-name="パス 17064"/>
      <path d="M171.478 23.356a19.3 19.3 0 0 1-1.585 7.773 20.048 20.048 0 0 1-4.3 6.338 20.665 20.665 0 0 1-6.369 4.276 19.3 19.3 0 0 1-7.773 1.585 19.5 19.5 0 0 1-7.8-1.585 20.158 20.158 0 0 1-10.7-10.673 19.267 19.267 0 0 1-1.585-7.773 19.276 19.276 0 0 1 1.585-7.774 20.158 20.158 0 0 1 10.693-10.667 19.5 19.5 0 0 1 7.8-1.585 19.3 19.3 0 0 1 7.776 1.585 20.158 20.158 0 0 1 10.673 10.7 19.511 19.511 0 0 1 1.585 7.8m-9.627 0a11.718 11.718 0 0 0-.808-4.366 11.058 11.058 0 0 0-5.561-5.979 9.267 9.267 0 0 0-4.035-.9 9.747 9.747 0 0 0-4.066.867 10.487 10.487 0 0 0-3.35 2.393 11.137 11.137 0 0 0-2.242 3.558 12.2 12.2 0 0 0 0 8.729 11.116 11.116 0 0 0 2.242 3.558 10.487 10.487 0 0 0 3.35 2.393 9.717 9.717 0 0 0 4.066.867 9.522 9.522 0 0 0 4.035-.867 10.6 10.6 0 0 0 3.319-2.393 11.154 11.154 0 0 0 2.242-3.527 11.481 11.481 0 0 0 .808-4.335" data-name="パス 17065"/>
      <path d="M200.252 2a1.489 1.489 0 0 1 .655.968 1.66 1.66 0 0 1-.218 1.28l-2.311 3.684a1.418 1.418 0 0 1-1.031.751 2.345 2.345 0 0 1-1.092-.126 7.034 7.034 0 0 0-2.5-.5 4.068 4.068 0 0 0-3.247 1.373 5.739 5.739 0 0 0-1.185 3.87v.187h6a1.6 1.6 0 0 1 1.623 1.623v4.56a1.6 1.6 0 0 1-1.623 1.623h-6v20.358a1.6 1.6 0 0 1-1.623 1.625h-5.434a1.6 1.6 0 0 1-1.623-1.625V21.293h-3.434a1.6 1.6 0 0 1-1.623-1.623v-4.558a1.6 1.6 0 0 1 1.623-1.623h3.434V13.3q.126-6.308 3.279-9.8a11.128 11.128 0 0 1 8.71-3.5 15.954 15.954 0 0 1 4.278.531A11.945 11.945 0 0 1 200.252 2" data-name="パス 17066"/>
      <path d="M225.35 2a1.489 1.489 0 0 1 .655.968 1.66 1.66 0 0 1-.218 1.28l-2.311 3.684a1.418 1.418 0 0 1-1.031.751 2.345 2.345 0 0 1-1.092-.126 7.034 7.034 0 0 0-2.5-.5 4.068 4.068 0 0 0-3.247 1.373 5.739 5.739 0 0 0-1.188 3.873v.187h6a1.6 1.6 0 0 1 1.623 1.623v4.557a1.6 1.6 0 0 1-1.623 1.623h-6v20.358a1.6 1.6 0 0 1-1.623 1.625h-5.433a1.6 1.6 0 0 1-1.623-1.625V21.293h-3.434a1.6 1.6 0 0 1-1.623-1.623v-4.558a1.6 1.6 0 0 1 1.623-1.623h3.434V13.3q.126-6.308 3.279-9.8a11.128 11.128 0 0 1 8.71-3.5 15.954 15.954 0 0 1 4.278.531A11.945 11.945 0 0 1 225.35 2" data-name="パス 17067"/>
      <path d="M233.355 0a5.111 5.111 0 0 1 3.714 1.5 4.958 4.958 0 0 1 1.53 3.684 4.959 4.959 0 0 1-1.53 3.686 5.11 5.11 0 0 1-3.714 1.5 4.9 4.9 0 0 1-3.653-1.5 5.061 5.061 0 0 1-1.469-3.686 5.06 5.06 0 0 1 1.469-3.684 4.9 4.9 0 0 1 3.653-1.5m4.5 41.653a1.6 1.6 0 0 1-1.623 1.623h-5.746a1.6 1.6 0 0 1-1.623-1.623V15.114a1.6 1.6 0 0 1 1.623-1.625h5.746a1.6 1.6 0 0 1 1.623 1.625Z" data-name="パス 17068"/>
      <path d="M263.804 34.289a1.949 1.949 0 0 1 1.155-.281 1.52 1.52 0 0 1 1.155.718l2.561 3.81a1.4 1.4 0 0 1 .25 1.249 1.9 1.9 0 0 1-.687 1 17.028 17.028 0 0 1-4.5 2.341 15.806 15.806 0 0 1-5.307.905 15.258 15.258 0 0 1-6.088-1.218 15.857 15.857 0 0 1-8.338-8.179 14.378 14.378 0 0 1-1.249-5.964 15.135 15.135 0 0 1 1.218-6.025 15.446 15.446 0 0 1 8.212-8.244 14.915 14.915 0 0 1 5.998-1.222 16.76 16.76 0 0 1 5.62.907 15.187 15.187 0 0 1 4.559 2.528 1.9 1.9 0 0 1 .594 1.029 1.454 1.454 0 0 1-.344 1.281l-2.811 3.5a1.305 1.305 0 0 1-1.123.655 2.663 2.663 0 0 1-1.186-.342 8.576 8.576 0 0 0-2.217-1.031 9.086 9.086 0 0 0-2.592-.344 6.856 6.856 0 0 0-5.152 2.03 7.26 7.26 0 0 0-1.967 5.277 7.006 7.006 0 0 0 2 5.183 6.931 6.931 0 0 0 5.122 2 8.632 8.632 0 0 0 2.685-.4 10.8 10.8 0 0 0 2.435-1.155" data-name="パス 17069"/>
      <path d="M278.392 0a5.111 5.111 0 0 1 3.714 1.5 4.958 4.958 0 0 1 1.53 3.684 4.959 4.959 0 0 1-1.53 3.686 5.109 5.109 0 0 1-3.714 1.5 4.9 4.9 0 0 1-3.653-1.5 5.062 5.062 0 0 1-1.469-3.686 5.06 5.06 0 0 1 1.469-3.684 4.9 4.9 0 0 1 3.653-1.5m4.5 41.653a1.6 1.6 0 0 1-1.623 1.623h-5.746a1.6 1.6 0 0 1-1.623-1.623V15.114a1.6 1.6 0 0 1 1.623-1.625h5.746a1.6 1.6 0 0 1 1.623 1.625Z" data-name="パス 17070"/>
      <path d="M319.214 41.596a1.678 1.678 0 0 1-.47 1.188 1.524 1.524 0 0 1-1.155.5h-6.121a1.522 1.522 0 0 1-1.153-.5 1.684 1.684 0 0 1-.468-1.188v-2.185a13.987 13.987 0 0 1-3.248 3 7.58 7.58 0 0 1-4.308 1.249 12.586 12.586 0 0 1-4.935-1.029 13.107 13.107 0 0 1-4.339-3 15.231 15.231 0 0 1-3.09-4.809 16.4 16.4 0 0 1-1.19-6.4 17.385 17.385 0 0 1 1.094-6.277 15.028 15.028 0 0 1 2.935-4.839 13.414 13.414 0 0 1 4.278-3.124 12.247 12.247 0 0 1 5.185-1.125 7.714 7.714 0 0 1 4.4 1.281 14.587 14.587 0 0 1 3.216 2.9v-2.182a1.6 1.6 0 0 1 1.621-1.623h6.121a1.6 1.6 0 0 1 1.625 1.623Zm-15.237-6.806a5.416 5.416 0 0 0 4.215-1.843 6.521 6.521 0 0 0 1.652-4.527 6.7 6.7 0 0 0-1.656-4.652 5.416 5.416 0 0 0-4.215-1.843 5.323 5.323 0 0 0-4.184 1.843 6.775 6.775 0 0 0-1.623 4.652 6.6 6.6 0 0 0 1.623 4.526 5.323 5.323 0 0 0 4.184 1.843" data-name="パス 17071"/>
      <path d="M325.183 1.623A1.6 1.6 0 0 1 326.806 0h6.121a1.567 1.567 0 0 1 1.153.468 1.568 1.568 0 0 1 .47 1.155V41.59a1.67 1.67 0 0 1-.47 1.186 1.512 1.512 0 0 1-1.153.5h-6.121a1.516 1.516 0 0 1-1.155-.5 1.668 1.668 0 0 1-.468-1.186Z" data-name="パス 17072"/>
    </g>
  </svg>
)

LogoLineOA.prototype = {
  width: string,
  height: string,
}

export default LogoLineOA