import React from "react"

const IconTouch = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="28" viewBox="0 0 27.303 28.826">
    <g fill="#595959" data-name="グループ 7797">
      <path d="M21.252 17.474a8.092 8.092 0 1 0-10.83-12.027L8.238 7.415a2.206 2.206 0 0 0 .496 3.617 2.2 2.2 0 0 0 .876 3.171 2.206 2.206 0 0 0 .803 3.133l-2.865 2.58a2.207 2.207 0 1 0 2.953 3.28l3.958-3.563a2.206 2.206 0 0 0 3.514.794Zm-4.264 1.86a.736.736 0 1 1-.985-1.093l3.827-3.445-.985-1.094-9.329 8.4a.736.736 0 1 1-.984-1.092l7.143-6.432-.984-1.093-2.734 2.46a.736.736 0 0 1-.984-1.092l2.733-2.461-.986-1.096-1.64 1.477a.736.736 0 1 1-.984-1.093l1.64-1.477-.985-1.093-.547.492A.736.736 0 1 1 9.22 8.51l2.187-1.97a6.621 6.621 0 1 1 8.86 9.84Z" data-name="パス 17088"/>
      <path d="M6.29 24.016a3.682 3.682 0 0 1 .272-5.194l-.985-1.095a5.15 5.15 0 0 0 6.892 7.654l-.985-1.093a3.682 3.682 0 0 1-5.194-.272Z" data-name="パス 17089"/>
    </g>
  </svg>
)

export default IconTouch