import React, { useEffect, useState } from "react"

import "./Star.scss"

const Star = props => {
  /** デバイス */
  const [device, setDevice] = useState("sp")

  const changePath = () => {
    setDevice(document.body.clientWidth > 990 ? "pc" : "sp")
  }

  useEffect(() => {
    changePath()
    window.addEventListener("resize", changePath())
    document.body.classList.add("FadeIn--stoppable")
  }, [device])

  return (
    <>
      {[...Array(10)].map((_, i) => {
        const colorNumber = Math.floor(Math.random() * 3 + 1)

        return (
          <span
            className={`Star Star--color${colorNumber} Star--size${
              i + 1
            } Star--${device}`}
            key={i}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="88.639"
              height="88.64"
              viewBox="0 0 88.639 88.64"
            >
              <path
                fill=""
                d="M78.212 33.767h-23.34V10.428a10.553 10.553 0 0 0-21.1 0v23.34H10.428a10.553 10.553 0 0 0 0 21.1h23.34v23.34a10.553 10.553 0 0 0 21.1 0V54.872h23.34a10.553 10.553 0 0 0 0-21.1Z"
              />
            </svg>
          </span>
        )
      })}
    </>
  )
}

export default Star
