import React, { useEffect, useState } from "react"

import Title from "../../components/Title/Title"
import Section from "../../components/Section/Section"
import Carousel from "../../components/Carousel/Carousel"

import "./CaseStudy.scss"

const CaseStudy = props => {
  /** デバイス */
  const [device, setDevice] = useState("sp")

  const changePath = () => {
    setDevice(document.body.clientWidth > 990 ? "pc" : "sp")
  }

  useEffect(() => {
    changePath()
    window.addEventListener("resize", changePath())
  }, [device])

  return (
    <Section id="CaseStudy" className={`CaseStudy CaseStudy--${device}`}>
      <Title title="CASE STUDY" text="当社のコンサルティング実績" />

      <Carousel
        items={[
          {
            imageUrl: `${process.env.IMAGE_PATH}casestudy/pc/img-oshicoco.webp`,
            title: "Oshicoco様",
            subText: "推し活応援メディア",
            instagramUrl: "https://www.instagram.com/oshikatsu_media",
            detail:
              "新規でアカウントを設立し、要件定義からデザイン構成、日々の投稿まで一気通貫で運用を支援。アカウント自体の収益化や自社ECへの流入接続などをコンサルティングにて伴走し、お客様から真に信頼されるコミュニティ（アカウント）創り、運用に成功しています。",
            history: [
              "2021年3月:アカウント設立",
              "2021年6月:フォロワー1万人突破",
              "2021年9月:アカウント収益化",
              "2021年9月:D2C事業開始（自社商品開発、自社EC）",
              "2022年2月:フォロワー4万人突破",
            ],
          },
          {
            imageUrl: `${process.env.IMAGE_PATH}casestudy/pc/img-urarin.webp`,
            title: "うらりん様",
            subText: "美容・化粧品インスタグラマー",
            instagramUrl: undefined,
            detail:
              "新規でアカウントを設立し、要件定義からデザイン構成、日々の投稿まで一気通貫で運用を代行。公式LINEを使ったリスト誘導、教育、情報商材の販売にて成果を上げ、バイアウトを実現。",
            history: [
              "2020年8月:アカウント設立",
              "2020年10月:アカウント収益化",
              "2020年11月:フォロワー1万人突破",
              "2020年12月:アカウント収益100万円/月突破",
              "2021年1月:アカウントbuy out",
            ],
          },
          {
            imageUrl: `${process.env.IMAGE_PATH}casestudy/pc/img-kanamaru.webp`,
            title: "かなまる様",
            subText: "トレーニング・健康",
            instagramUrl: "https://instagram.com/diet_kanamaru_169cm",
            detail:
              "【2ヶ月間のサポート実績】\n・市場調査、アカウント分析\n・アカウントコンセプトの再構築\n・投稿デザイン構成のフルリニューアル\n・アカウント管理、営業代行　など",
            history: [
              "2022年1月:コンサルティング開始",
              "2022年1月:売上300%増加（2021年12月比較）",
              "2022年2月:売上500%増加（2021年12月比較）",
            ],
          },
        ]}
      />
    </Section>
  )
}

export default CaseStudy
