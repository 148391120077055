import { string } from "prop-types"
import React from "react"

const LogoAninco = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 212.6 43.199">
    <g fill="#fff">
      <title>Anin Co.</title>
      <path d="M1.14 42.6h5.22a1.808 1.808 0 0 0 1.74-1.2l2.94-6.48h17.94l2.94 6.48a1.725 1.725 0 0 0 1.74 1.2h5.22a1.059 1.059 0 0 0 1.02-1.56L21.42.66A1.02 1.02 0 0 0 20.4 0h-.6a1.078 1.078 0 0 0-1.02.66L.12 41.04a1.059 1.059 0 0 0 1.02 1.56Zm12.84-14.34 5.88-13.2h.18l6 13.2Zm31.38 13.2a1.18 1.18 0 0 0 1.14 1.14h5.46a1.139 1.139 0 0 0 1.14-1.14v-25.8h.06L78.24 43.2h1.44a1.128 1.128 0 0 0 1.14-1.08V1.74A1.18 1.18 0 0 0 79.68.6h-5.52a1.139 1.139 0 0 0-1.14 1.14v24.78h-.06L48 0h-1.5a1.128 1.128 0 0 0-1.14 1.08Zm46.38 0a1.18 1.18 0 0 0 1.14 1.14h5.58a1.18 1.18 0 0 0 1.14-1.14V1.74A1.18 1.18 0 0 0 98.46.6h-5.58a1.18 1.18 0 0 0-1.14 1.14Zm18.78 0a1.18 1.18 0 0 0 1.14 1.14h5.46a1.139 1.139 0 0 0 1.14-1.14v-25.8h.06L143.4 43.2h1.44a1.128 1.128 0 0 0 1.14-1.08V1.74A1.18 1.18 0 0 0 144.84.6h-5.52a1.139 1.139 0 0 0-1.14 1.14v24.78h-.06L113.16 0h-1.5a1.128 1.128 0 0 0-1.14 1.08Z"/>
      <path d="M160.72 28.64A14.34 14.34 0 0 0 175.16 43a13.729 13.729 0 0 0 9.72-3.88.762.762 0 0 0 .04-1.08l-2.48-2.56a.737.737 0 0 0-1 0 9.6 9.6 0 0 1-6.12 2.28 9.058 9.058 0 0 1-8.96-9.24 9.069 9.069 0 0 1 8.92-9.32 9.463 9.463 0 0 1 6.16 2.36.641.641 0 0 0 1 0l2.44-2.52a.743.743 0 0 0-.04-1.12 13.538 13.538 0 0 0-9.68-3.72 14.408 14.408 0 0 0-14.44 14.44Zm26.32 5.92a8.368 8.368 0 0 0 8.2 8.44 8.368 8.368 0 0 0 8.2-8.44 8.35 8.35 0 0 0-8.2-8.36 8.35 8.35 0 0 0-8.2 8.36Zm4.36 0a3.913 3.913 0 0 1 3.84-4.04 3.939 3.939 0 0 1 3.84 4.04 3.985 3.985 0 0 1-3.84 4.12 3.959 3.959 0 0 1-3.84-4.12Zm14.6 5.16a3.3 3.3 0 0 0 3.28 3.28 3.329 3.329 0 0 0 3.32-3.28 3.364 3.364 0 0 0-3.32-3.32 3.329 3.329 0 0 0-3.28 3.32Z"/>
    </g>
  </svg>
)

LogoAninco.prototype = {
  width: string,
  height: string,
}

export default LogoAninco