// インスタグラマー向け
import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { init, send } from 'emailjs-com';

import FormLabel from "../../components/Form/FormLabel"
import Input from "../../components/Form/Input"
import Select from "../../components/Form/Select"
import Radio from "../../components/Form/Radio"
import Textarea from "../../components/Form/Textarea"
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch.js"

const Instagram = props => {
  /** お名前 */
  const [names, setNames] = useState("")
  /** ふりがな */
  const [ruby, setRuby] = useState("")
  /** アカウント有無 */
  const [isAccount, setIsAccount] = useState("なし")
  /** アカウント名 */
  const [accountId, setAccountId] = useState("")
  /** 電話番号 */
  const [tel, setTel] = useState("")
  /** メールアドレス */
  const [email, setEMail] = useState("")
  /** お問い合わせ種別 */
  const [inquiry, setInquiry] = useState("運用代行")
  /** お問い合わせ内容 */
  const [inquiryContents, setInquiryContents] = useState("")
  /** 利用規約/プライバs−ポリシーにどういしたか */
  const [agree, setAgree] = useState(false)
  /** 送信中 */
  const [isSending, setIsSending] = useState(false)
  /** デバイス */
  const [device, setDevice] = useState("sp")

  const changeForm = (type, value) => {
    if (type === "names") {
      setNames(value)
    } else if (type === "ruby") {
      setRuby(value)
    } else if (type === "account") {
      setIsAccount(value)
    } else if (type === "account_id") {
      setAccountId(value)
    } else if (type === "tel") {
      setTel(value)
    } else if (type === "email") {
      setEMail(value)
    } else if (type === "inquiry") {
      setInquiry(value)
    } else if (type === "inquiryContents") {
      setInquiryContents(value)
    }
  }

  const toggleForm = value => {
    setAgree(value)
  }

  const changePath = () => {
    setDevice(document.body.clientWidth > 990 ? "pc" : "sp")
  }

  useEffect(() => {
    changePath()

    window.addEventListener("resize", changePath())
  }, [device])

  /** メール送信 */
  const sendMail = () => {
    const userID = process.env.REACT_APP_USER_ID;
    const serviceID = process.env.REACT_APP_SERVICE_ID;
    const templateID = process.env.REACT_APP_TEMPLATE_ID_INSTAGRAMER;

    if (
      userID !== undefined &&
      serviceID !== undefined &&
      templateID !== undefined
    ) {
      init(userID);
    };

    const template_param = {
      names: names,
      ruby: ruby,
      inquiry: inquiry,
      isAccount: isAccount,
      accountId: accountId,
      email: email,
      tel: tel,
      inquiryContents: inquiryContents,
    };

    send(serviceID, templateID, template_param).then(() => {
      window.alert('お問い合わせを送信致しました。');

      setNames('');
      setRuby('');
      setIsAccount('なし');
      setAccountId('');
      setTel('');
      setEMail('');
      setInquiry('運用代行');
      setInquiryContents('');
      setAgree(false);
      setIsSending(false);
    });
  }

  const handleClick = (e) => {
    e.preventDefault();
    setIsSending(true);
    sendMail();
  };

  /** 必須項目の入力判定 */
  const disableSend = names === ""
    || ruby === ""
    || inquiry === ""
    || email === ""
    || tel === ""
    || inquiryContents === "";

  return (
    <div className="Tab__panel">
      <form>
        <div className="Contact__formItem">
          <FormLabel title="お名前" label="必須" id="names" />
          <Input
            type="text"
            placeholder="お名前を入力してください。"
            name="names"
            id="names"
            required
            value={names}
            onChange={e => changeForm("names", e.target.value)}
            autocomplete="name"
          />
        </div>

        <div className="Contact__formItem">
          <FormLabel title="ふりがな" label="必須" id="ruby" />
          <Input
            type="text"
            placeholder="ふりがなを入力してください。"
            name="ruby"
            id="ruby"
            required
            value={ruby}
            onChange={e => changeForm("ruby", e.target.value)}
            autocomplete="name"
          />
        </div>

        <div className="Contact__formItem">
          <FormLabel title="お問い合わせ種別" label="必須" id="inquiry" />
          <Select
            id="inquiry"
            name="inquiry"
            placeholder="お問い合わせ種別を選択してください。"
            options={[
              { text: "運用代行", value: "運用代行" },
              { text: "コンサルティング", value: "コンサルティング" },
              { text: "その他", value: "その他" },
            ]}
            required
            value={inquiry}
            onChange={e => changeForm("inquiry", e.target.value)}
          />
        </div>

        <div className="Contact__formItem">
          <FormLabel title="アカウント有無" />
          <div>
            <Radio
              id="accountTrue"
              name="account"
              value="あり"
              checked={isAccount === "あり"}
              onChange={() => changeForm("account", "あり")}
            >
              あり
            </Radio>
            <Radio
              id="accountFalse"
              name="account"
              value="なし"
              checked={isAccount === "なし"}
              onChange={() => changeForm("account", "なし")}
            >
              なし
            </Radio>
          </div>
        </div>

        {isAccount === "あり" && (
          <div className="Contact__formItem">
            <FormLabel title="アカウントID" id="account_id" />
            <Input
              type="text"
              placeholder="アカウントIDを入力してください。"
              name="accountId"
              id="account_id"
              value={accountId}
              onChange={e => changeForm("account_id", e.target.value)}
            />
          </div>
        )}

        <div className="Contact__formItem">
          <FormLabel title="メールアドレス" label="必須" id="email" />
          <Input
            type="email"
            placeholder="メールアドレスを入力してください。"
            name="email"
            id="email"
            required
            value={email}
            onChange={e => changeForm("email", e.target.value)}
            autocomplete="email"
          />
        </div>

        <div className="Contact__formItem">
          <FormLabel title="電話番号" label="必須" id="tel" />
          <Input
            type="tel"
            placeholder="電話番号を入力してください。"
            name="tel"
            id="tel"
            required={true}
            value={tel}
            onChange={e => changeForm("tel", e.target.value)}
          />
        </div>

        <div className="Contact__formItem">
          <FormLabel
            title="お問い合わせ内容"
            label="必須"
            id="inquiryContents"
          />
          <Textarea
            id="inquiryContents"
            name="inquiryContents"
            placeholder="お問い合わせ内容を入力してください。（最大2000文字）"
            value={inquiryContents}
            onChange={e => changeForm("inquiryContents", e.target.value)}
          />
        </div>

        <div className="Contact__formItem Contact__formItem--terms">
          {device === "pc" && (
            <div className="Contact__termsCheckBox">
              <input
                type="checkbox"
                name="terms"
                required
                value={agree}
                checked={agree}
                onChange={e => toggleForm(e.target.checked)}
              />
            </div>
          )}

          <p className="Contact__termsConfirm">
            {/* <a href="#dummy">ご利用規約</a>・ */}
            <Link to="/privacy/">プライバシーポリシー</Link>に同意する
          </p>

          {device === "sp" && (
            <ToggleSwitch
              value={agree}
              required
              onChange={e => toggleForm(e.target.checked)}
            />
          )}
        </div>

        <div className="Contact__formSubmit">
          <button
            className="Contact__formSubmitButton"
            onClick={handleClick}
            disabled={disableSend || !agree || isSending}
          >
            {isSending ? "送信中" : "送信する"}
          </button>
        </div>
      </form>
    </div>
  )
}

export default Instagram
