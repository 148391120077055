import React from "react"

// widthとheightを可変にしたい場合はpropsで受け取る
const LogoAninco = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="435.218" height="89.16" viewBox="0 0 435.218 89.16">
    <g fill="#fff">
      <title>Anin Co.</title>
      <path d="M2.338 87.33h10.7a3.707 3.707 0 0 0 3.567-2.46l6.027-13.284H59.41l6.027 13.284c.861 1.722 1.722 2.46 3.567 2.46h10.7a2.172 2.172 0 0 0 2.091-3.2L43.912 1.353A2.091 2.091 0 0 0 41.821 0h-1.23A2.21 2.21 0 0 0 38.5 1.353L.247 84.13a2.172 2.172 0 0 0 2.091 3.2Zm26.322-29.4 12.054-27.06h.369l12.3 27.06Zm64.329 27.063a2.419 2.419 0 0 0 2.337 2.337h11.193a2.335 2.335 0 0 0 2.337-2.337v-52.89h.123l51.414 56.457h2.954a2.312 2.312 0 0 0 2.337-2.214V3.567a2.419 2.419 0 0 0-2.337-2.337h-11.318a2.335 2.335 0 0 0-2.337 2.337v50.8h-.123L98.401 0h-3.075a2.312 2.312 0 0 0-2.337 2.214Zm95.079 0a2.419 2.419 0 0 0 2.337 2.337h11.442a2.419 2.419 0 0 0 2.337-2.337V3.567a2.419 2.419 0 0 0-2.337-2.337h-11.442a2.419 2.419 0 0 0-2.337 2.337Zm38.5 0a2.419 2.419 0 0 0 2.336 2.337h11.193a2.335 2.335 0 0 0 2.337-2.337v-52.89h.123l51.414 56.457h2.952a2.312 2.312 0 0 0 2.337-2.214V3.567a2.419 2.419 0 0 0-2.337-2.337h-11.316a2.335 2.335 0 0 0-2.337 2.337v50.8h-.123L231.979 0h-3.075a2.313 2.313 0 0 0-2.337 2.214Z"/>
      <path d="M327.567 59.363A29.756 29.756 0 0 0 357.53 89.16a28.487 28.487 0 0 0 20.169-8.051 1.58 1.58 0 0 0 .083-2.241l-5.146-5.312a1.529 1.529 0 0 0-2.075 0 19.919 19.919 0 0 1-12.7 4.731c-10.624 0-18.592-8.8-18.592-19.173 0-10.458 7.885-19.339 18.509-19.339a19.636 19.636 0 0 1 12.782 4.9 1.329 1.329 0 0 0 2.075 0l5.063-5.229a1.543 1.543 0 0 0-.083-2.324A28.091 28.091 0 0 0 357.53 29.4a29.9 29.9 0 0 0-29.963 29.963Zm54.614 12.284c0 9.545 7.636 17.513 17.015 17.513s17.015-7.968 17.015-17.513A17.325 17.325 0 0 0 399.196 54.3a17.325 17.325 0 0 0-17.015 17.347Zm9.047 0a8.12 8.12 0 0 1 7.968-8.383 8.173 8.173 0 0 1 7.968 8.383c0 4.731-3.569 8.549-7.968 8.549-4.482 0-7.968-3.818-7.968-8.549Zm30.295 10.707a6.837 6.837 0 0 0 6.806 6.806 6.909 6.909 0 0 0 6.889-6.806 6.98 6.98 0 0 0-6.889-6.889 6.909 6.909 0 0 0-6.806 6.889Z"/>
    </g>
  </svg>
)

export default LogoAninco