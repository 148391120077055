import React, { useEffect, useState } from "react"

import "./LineOA.scss"
import LogoLineOA_SP from "../../images/contact/sp/logo-lineOA"
import LogoLineOA_PC from "../../images/contact/pc/logo-lineOA"

const LineOA = props => {
  const [device, setDevice] = useState("sp")
  const LINE_URL = "https://line.me/R/ti/p/%40898ocsxg#~"
  const Inner = device === "sp" ? "a" : "div";
  const InnerAttr = device === "sp"
    ? {
      className: "LineOA__inner",
      href: LINE_URL,
      target: "_blank",
      rel: "noopener noreferrer"
    }
    : { className: "LineOA__inner" }

  const changePath = () => {
    setDevice(document.body.clientWidth > 990 ? "pc" : "sp")
  }

  useEffect(() => {
    changePath()

    window.addEventListener("resize", changePath())
  }, [device])

  return (
    <div className={`LineOA LineOA--${device}`}>
      <Inner {...InnerAttr}>
        <div className="LineOA__item">
          <p>
            {device === "sp" ? (
              <div className="LineOA__lineOALogo">
                <LogoLineOA_SP width="280" height="22" />
              </div>
            ) : (
              <div class="LineOA__lineOALogo">
                <LogoLineOA_PC width="574" height="44" />
              </div>
            )}
          </p>
          <p>LINEからのお問い合わせもお待ちしております。</p>
        </div>
        {device === "pc" && (
          <div className="LineOA__item">
            <img
              src={`${process.env.IMAGE_PATH}contact/img-lineQR.webp`}
              alt="LINE Official Account"
              width={150}
              height={150}
              loading="lazy"
            />
          </div>
        )}
      </Inner>
    </div>
  )
}

export default LineOA
