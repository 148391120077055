
import React from "react"

const IconConsulting = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="86" height="86" viewBox="0 0 103.338 103.338">
    <g fill="#fff" data-name="グループ 7802">
      <path d="M27.248 0a12.273 12.273 0 0 0-12.11 12.312 12.11 12.11 0 0 0 24.22 0A12.274 12.274 0 0 0 27.248 0Zm0 18.367a6.062 6.062 0 0 1-6.055-6.055 6.058 6.058 0 1 1 12.11 0 6.062 6.062 0 0 1-6.055 6.055Z" data-name="パス 17047"/>
      <path d="M94.255 0H51.467a9.093 9.093 0 0 0-9.082 9.082v20.661l-2.661-2.661a9.022 9.022 0 0 0-6.424-2.66H15.137A15.155 15.155 0 0 0 0 39.559v18.165a9.084 9.084 0 0 0 12.11 8.564v27.967a9.078 9.078 0 0 0 15.137 6.764 9.078 9.078 0 0 0 15.137-6.764V54.181a9.117 9.117 0 0 0 7.09-.442l10.194-5.1h34.587a9.093 9.093 0 0 0 9.082-9.082V9.082A9.093 9.093 0 0 0 94.255 0ZM58.876 42.267 46.77 48.321a3.027 3.027 0 0 1-3.5-.566l-1.77-1.774a3.029 3.029 0 0 0-5.168 2.131s0 .006 0 .009v46.134a3.027 3.027 0 1 1-6.055 0V63.779a3.027 3.027 0 0 0-6.055 0v30.476a3.027 3.027 0 1 1-6.055 0v-54.7a3.027 3.027 0 1 0-6.055 0v18.169a3.027 3.027 0 1 1-6.055 0V39.559a9.093 9.093 0 0 1 9.082-9.082H33.3a3.007 3.007 0 0 1 2.14.887l9.023 9.023a3.028 3.028 0 0 0 3.495.567l8.208-4.1a3.028 3.028 0 0 1 2.708 5.417Zm38.407-2.708a3.031 3.031 0 0 1-3.027 3.027H66.084c.018-.052.039-.1.056-.155a9.048 9.048 0 0 0 .4-4l16.56-8.274a3.028 3.028 0 1 0-2.708-5.416l-16.559 8.278a9.075 9.075 0 0 0-10.373-1.584l-5.02 2.51V9.082a3.031 3.031 0 0 1 3.027-3.027h42.788a3.031 3.031 0 0 1 3.027 3.027Z" data-name="パス 17048"/>
    </g>
  </svg>
)

export default IconConsulting