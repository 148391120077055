import React, { useEffect, useState } from "react"
import { string } from "prop-types"
import Slider from "react-slick"

import IconInstagram from "../../images/service/sp/icon-instagram"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import "./Carousel.scss"

const Carousel = props => {
  /** デバイス */
  const [device, setDevice] = useState("sp")

  const changePath = () => {
    setDevice(document.body.clientWidth > 990 ? "pc" : "sp")
  }

  useEffect(() => {
    changePath()
    window.addEventListener("resize", changePath())
  }, [device])

  const settingsSP = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    centerMode: false,
  }

  const settingsPC = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 1,
    arrows: true,
    // centerMode: true, // initialSlideが適応されなくなるため
  }

  const settings = device === "sp" ? settingsSP : settingsPC

  return (
    <div className="Carousel">
      <Slider {...settings}>
        {props.items.map((item, index) => {
          const ItemInner = item.instagramUrl ? "a" : "div"
          return (
            <div className="Carousel__item" key={index}>
              <ItemInner
                className="Carousel__itemInner"
                href={item.instagramUrl || undefined}
                target={item.instagramUrl ? "_blank" : undefined}
                rel={item.instagramUrl ? "noopener noreferrer" : undefined}
              >
                <div className="Carousel__thumbnail">
                  <img
                    src={item.imageUrl}
                    alt={`${item.title}の画像`}
                    loading="lazy"
                    className="Carousel__thumbnailImage"
                  />
                  <div
                    className={`Carousel__instagram${
                      item.instagramUrl ? "" : " Carousel__instagram--noLink"
                    }`}
                  >
                    <i>
                      <IconInstagram width="19.54" height="19.54" />
                      <span>アカウント</span>
                    </i>
                  </div>
                </div>
                <div className="Carousel__info">
                  <div className="Carousel__headerInfo">
                    <div className="Carousel__headerInfoMain">
                      <div className="Carousel__subText">{item.subText}</div>
                      <div className="Carousel__title">{item.title}</div>
                    </div>
                  </div>
                  <div className="Carousel__content">
                    <div className="Carousel__contentItem">
                      <div className="Carousel__contentLabel">運用期間</div>
                      <ul className="Carousel__contentHistory">
                        {item.history.map((historyItem, historyIndex) => (
                          <li
                            className="Carousel__contentHistoryItem"
                            key={`historyItem-${historyIndex}`}
                          >
                            {historyItem}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="Carousel__contentItem">
                      <div className="Carousel__contentLabel">実施施策</div>
                      <p
                        className="Carousel__contentParagraph"
                        dangerouslySetInnerHTML={{
                          __html: item.detail.replace(/\r?\n/g, "<br />"),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </ItemInner>
            </div>
          )
        })}
      </Slider>
    </div>
  )
}

Carousel.prototype = {
  items:
    Array <
    {
      title: string,
      subText: string,
      instagramUrl: string,
      detail: string,
      history: Array < string > [],
    } >
    [],
}

export default Carousel
