import React from "react"

import "./Badge.scss"

const Badge = props => (
  <div
    className={`Badge${props.size ? ` Badge--${props.size}` : ""}${
      props.color ? ` Badge--${props.color}` : ""
    }${props.addClassName ? ` ${props.addClassName}` : ""}`}
  >
    <span className="Badge__text">{props.text}</span>
  </div>
)

Badge.prototype = {
  size: "large" | "medium",
  color: "white" | "red",
}

export default Badge
