import { string } from "prop-types"
import React, { useState } from "react"

import "./Expand.scss"

const Expand = props => {
  const [isShow, setIsShow] = useState(false)

  const onClick = () => {
    setIsShow(!isShow)
  }

  return (
    <div
      className={`Expand${props.addClassName ? ` ${props.addClassName}` : ""}`}
    >
      <p
        className={`Expand__text${isShow ? "" : " Expand__text--hidden"}`}
        dangerouslySetInnerHTML={{
          __html: props.text.replace(/\r?\n/g, "<br />"),
        }}
      />
      {!isShow && (
        <p className="Expand__buttonWrapper">
          <button className="Expand__button" onClick={onClick}>
            <span className="Expand__buttonText">続きを見る</span>
            <span className="Expand__buttonArrow" />
          </button>
        </p>
      )}
    </div>
  )
}

Expand.prototype = {
  text: string,
}

export default Expand
