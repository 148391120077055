import { string } from "prop-types"
import React from "react"

const LogoLineOA = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 279.538 21.496">
  <g fill="#fff" data-name="グループ 7850">
    <title>LINE Official Account</title>
    <path d="M19.197 1.637h-2.872a.8.8 0 0 0-.8.8v17.84a.8.8 0 0 0 .8.8h2.873a.8.8 0 0 0 .8-.8V2.434a.8.8 0 0 0-.8-.8" data-name="パス 42716"/>
    <path d="M38.969 1.637h-2.872a.8.8 0 0 0-.8.8v10.6L27.122 1.991a.9.9 0 0 0-.063-.081v-.006l-.047-.048-.015-.014-.043-.035-.02-.016-.042-.029-.024-.015-.043-.023-.025-.012a.4.4 0 0 0-.046-.02l-.025-.009-.054-.015-.028-.007-.046-.01-.033-.006h-2.984a.8.8 0 0 0-.8.8v17.832a.8.8 0 0 0 .8.8h2.873a.8.8 0 0 0 .8-.8v-10.6l8.188 11.057a.766.766 0 0 0 .2.2l.008.007.049.03.023.013.037.018.039.017.024.009a.56.56 0 0 0 .054.017h.011a.787.787 0 0 0 .2.028h2.873a.8.8 0 0 0 .8-.8V2.434a.8.8 0 0 0-.8-.8" data-name="パス 42717"/>
    <path d="M12.273 16.605h-7.8V2.433a.8.8 0 0 0-.8-.8H.8a.8.8 0 0 0-.8.8v17.844a.792.792 0 0 0 .223.55l.01.012.012.011a.793.793 0 0 0 .555.227h11.473a.8.8 0 0 0 .8-.8v-2.874a.8.8 0 0 0-.8-.8" data-name="パス 42718"/>
    <path d="M54.833 1.637H43.357a.789.789 0 0 0-.553.225l-.01.008-.015.014a.794.794 0 0 0-.221.549v17.844a.794.794 0 0 0 .223.551l.01.012.012.01a.794.794 0 0 0 .551.223h11.479a.8.8 0 0 0 .8-.8v-2.869a.8.8 0 0 0-.8-.8h-7.8v-3.015h7.8a.8.8 0 0 0 .8-.8v-2.87a.8.8 0 0 0-.8-.8h-7.8V6.105h7.8a.8.8 0 0 0 .8-.8v-2.87a.8.8 0 0 0-.8-.8" data-name="パス 42719"/>
    <path d="M188.87 21.161h-3.77a.649.649 0 0 1-.5-.184.959.959 0 0 1-.231-.429l-1.199-3.279h-7.722l-1.165 3.279a1.454 1.454 0 0 1-.245.429.6.6 0 0 1-.49.184h-3.586q-.614 0-.4-.582l7.141-18.327a1.123 1.123 0 0 1 .352-.46 1.161 1.161 0 0 1 .751-.214h3.249a1.109 1.109 0 0 1 .72.214 1.433 1.433 0 0 1 .382.46l7.142 18.329c.142.389 0 .582-.429.582m-7.171-7.968-2.39-6.589-2.391 6.589h4.781Z" data-name="パス 42720"/>
    <path d="M200.272 16.691a.949.949 0 0 1 .562-.137.74.74 0 0 1 .562.35l1.247 1.855a.68.68 0 0 1 .121.608.921.921 0 0 1-.334.486 8.289 8.289 0 0 1-2.189 1.14 7.694 7.694 0 0 1-2.583.441 7.432 7.432 0 0 1-2.965-.593 7.721 7.721 0 0 1-4.058-3.982 7 7 0 0 1-.608-2.9 7.367 7.367 0 0 1 .589-2.937 7.519 7.519 0 0 1 4-4.008 7.26 7.26 0 0 1 2.92-.6 8.159 8.159 0 0 1 2.736.442 7.393 7.393 0 0 1 2.219 1.231.924.924 0 0 1 .289.5.708.708 0 0 1-.164.627l-1.368 1.7a.638.638 0 0 1-.547.319 1.3 1.3 0 0 1-.577-.166 4.181 4.181 0 0 0-1.08-.5 4.412 4.412 0 0 0-1.261-.167 3.336 3.336 0 0 0-2.508.988 3.534 3.534 0 0 0-.958 2.569 3.41 3.41 0 0 0 .972 2.523 3.374 3.374 0 0 0 2.493.972 4.2 4.2 0 0 0 1.307-.2 5.256 5.256 0 0 0 1.185-.562" data-name="パス 42721"/>
    <path d="M214.244 16.691a.949.949 0 0 1 .562-.137.737.737 0 0 1 .562.35l1.248 1.855a.68.68 0 0 1 .121.608.917.917 0 0 1-.335.486 8.283 8.283 0 0 1-2.188 1.14 7.694 7.694 0 0 1-2.583.441 7.424 7.424 0 0 1-2.964-.593 7.719 7.719 0 0 1-4.059-3.982 7 7 0 0 1-.608-2.9 7.367 7.367 0 0 1 .593-2.933 7.519 7.519 0 0 1 4-4.013 7.26 7.26 0 0 1 2.918-.593 8.159 8.159 0 0 1 2.736.442 7.393 7.393 0 0 1 2.212 1.226.924.924 0 0 1 .289.5.706.706 0 0 1-.168.624l-1.367 1.7a.636.636 0 0 1-.547.319 1.3 1.3 0 0 1-.578-.166 4.175 4.175 0 0 0-1.079-.5 4.412 4.412 0 0 0-1.261-.167 3.338 3.338 0 0 0-2.508.988 3.538 3.538 0 0 0-.958 2.569 3.41 3.41 0 0 0 .972 2.523 3.375 3.375 0 0 0 2.493.972 4.192 4.192 0 0 0 1.306-.2 5.263 5.263 0 0 0 1.186-.562" data-name="パス 42722"/>
    <path d="M225.625 6.322a7.443 7.443 0 0 1 2.964.593 7.648 7.648 0 0 1 2.417 1.626 7.862 7.862 0 0 1 1.642 2.417 7.284 7.284 0 0 1 .608 2.964 7.211 7.211 0 0 1-.608 2.934 7.847 7.847 0 0 1-1.642 2.416 7.62 7.62 0 0 1-2.417 1.627 7.712 7.712 0 0 1-5.927 0 7.593 7.593 0 0 1-4.043-4.043 7.372 7.372 0 0 1-.593-2.934 7.447 7.447 0 0 1 .593-2.964 7.585 7.585 0 0 1 4.042-4.036 7.44 7.44 0 0 1 2.964-.593m0 11.065a3.116 3.116 0 0 0 2.3-.988 3.358 3.358 0 0 0 .684-1.109 3.823 3.823 0 0 0 .243-1.368 3.909 3.909 0 0 0-.243-1.4 3.358 3.358 0 0 0-.684-1.109 3.2 3.2 0 0 0-3.572-.73 2.96 2.96 0 0 0-1 .73 3.484 3.484 0 0 0-.669 1.109 3.888 3.888 0 0 0-.243 1.4 3.8 3.8 0 0 0 .243 1.368 3.484 3.484 0 0 0 .669 1.109 2.96 2.96 0 0 0 1 .73 3.1 3.1 0 0 0 1.276.258" data-name="パス 42723"/>
    <path d="M249.245 14.594a7.55 7.55 0 0 1-.486 2.767 5.846 5.846 0 0 1-1.383 2.112 6.131 6.131 0 0 1-2.173 1.338 8.11 8.11 0 0 1-2.827.471 8.2 8.2 0 0 1-2.858-.471 6.139 6.139 0 0 1-2.174-1.338 5.846 5.846 0 0 1-1.383-2.112 7.569 7.569 0 0 1-.486-2.767v-7.2a.779.779 0 0 1 .79-.79h2.736a.778.778 0 0 1 .791.79v6.84a3.1 3.1 0 0 0 .714 2.158 2.4 2.4 0 0 0 1.869.79 2.355 2.355 0 0 0 1.855-.79 3.143 3.143 0 0 0 .7-2.158V7.389a.778.778 0 0 1 .79-.79h2.736a.778.778 0 0 1 .79.79Z" data-name="パス 42724"/>
    <path d="M265.565 20.932a.765.765 0 0 1-.562.228h-2.827a.778.778 0 0 1-.79-.79v-7.2a2.72 2.72 0 0 0-.654-1.884 2.2 2.2 0 0 0-1.718-.73 2.27 2.27 0 0 0-.683.122 2.512 2.512 0 0 0-.715.364 2.411 2.411 0 0 0-.593.639 1.928 1.928 0 0 0-.288.972v7.722a.778.778 0 0 1-.791.79h-2.948a.78.78 0 0 1-.791-.79V7.451a.781.781 0 0 1 .791-.791h2.948a.78.78 0 0 1 .791.791v1.58a3.666 3.666 0 0 1 1.58-1.93 4.39 4.39 0 0 1 2.31-.684 4.707 4.707 0 0 1 3.785 1.626 6.658 6.658 0 0 1 1.383 4.454v7.873a.765.765 0 0 1-.228.562" data-name="パス 42725"/>
    <path d="M279.521 19.674a.725.725 0 0 1-.244.364 6.574 6.574 0 0 1-1.777.943 6.125 6.125 0 0 1-2.082.364 4.707 4.707 0 0 1-3.755-1.52 6.374 6.374 0 0 1-1.322-4.316v-5.14h-1.946a.739.739 0 0 1-.562-.244.816.816 0 0 1-.228-.577v-2.1a.82.82 0 0 1 .228-.578.742.742 0 0 1 .562-.243h1.976V3.774a.778.778 0 0 1 .79-.79h2.736a.778.778 0 0 1 .79.79V6.63h2.918a.74.74 0 0 1 .562.243.816.816 0 0 1 .228.578v2.1a.812.812 0 0 1-.228.577.738.738 0 0 1-.562.244h-2.86v5.012q0 1.947 1.52 1.946a1.9 1.9 0 0 0 .624-.091 2.465 2.465 0 0 0 .5-.244.636.636 0 0 1 .471-.106.583.583 0 0 1 .38.289l1.185 2.006a.636.636 0 0 1 .091.486" data-name="パス 42726"/>
    <path d="M83.474 11.37a9.394 9.394 0 0 1-.772 3.784 9.759 9.759 0 0 1-2.1 3.085 10.06 10.06 0 0 1-3.1 2.081 9.394 9.394 0 0 1-3.784.772 9.494 9.494 0 0 1-3.8-.772 9.813 9.813 0 0 1-5.211-5.2 9.379 9.379 0 0 1-.772-3.784 9.383 9.383 0 0 1 .772-3.785 9.813 9.813 0 0 1 5.211-5.2 9.494 9.494 0 0 1 3.8-.772 9.394 9.394 0 0 1 3.784.772 9.813 9.813 0 0 1 5.2 5.211 9.5 9.5 0 0 1 .772 3.8m-4.686 0a5.7 5.7 0 0 0-.393-2.125 5.383 5.383 0 0 0-2.707-2.911 4.511 4.511 0 0 0-1.964-.437 4.745 4.745 0 0 0-1.979.422 5.105 5.105 0 0 0-1.632 1.168 5.421 5.421 0 0 0-1.091 1.732 5.936 5.936 0 0 0 0 4.249 5.411 5.411 0 0 0 1.091 1.732 5.105 5.105 0 0 0 1.631 1.165 4.73 4.73 0 0 0 1.979.422 4.635 4.635 0 0 0 1.964-.422 5.159 5.159 0 0 0 1.616-1.165 5.43 5.43 0 0 0 1.091-1.713 5.589 5.589 0 0 0 .393-2.11" data-name="パス 42727"/>
    <path d="M97.481.972a.725.725 0 0 1 .319.471.808.808 0 0 1-.106.623L96.569 3.86a.69.69 0 0 1-.5.365 1.141 1.141 0 0 1-.538-.061 3.424 3.424 0 0 0-1.216-.243 1.98 1.98 0 0 0-1.58.668 2.794 2.794 0 0 0-.578 1.885v.091h2.919a.779.779 0 0 1 .79.79v2.22a.779.779 0 0 1-.79.79h-2.915v9.91a.779.779 0 0 1-.79.791h-2.645a.779.779 0 0 1-.79-.791v-9.91h-1.672a.778.778 0 0 1-.79-.79V7.356a.778.778 0 0 1 .79-.79h1.667v-.091a7.1 7.1 0 0 1 1.6-4.775 5.417 5.417 0 0 1 4.24-1.7 7.766 7.766 0 0 1 2.082.258 5.815 5.815 0 0 1 1.627.714" data-name="パス 42728"/>
    <path d="M109.698.972a.725.725 0 0 1 .319.471.808.808 0 0 1-.106.623l-1.125 1.794a.69.69 0 0 1-.5.365 1.141 1.141 0 0 1-.532-.061 3.424 3.424 0 0 0-1.216-.243 1.98 1.98 0 0 0-1.58.668 2.794 2.794 0 0 0-.578 1.885v.091h2.919a.779.779 0 0 1 .79.79v2.22a.779.779 0 0 1-.79.79h-2.919v9.91a.78.78 0 0 1-.79.791h-2.645a.779.779 0 0 1-.79-.791v-9.91h-1.671a.778.778 0 0 1-.79-.79V7.356a.778.778 0 0 1 .79-.79h1.671v-.091a7.1 7.1 0 0 1 1.6-4.773 5.417 5.417 0 0 1 4.24-1.7 7.766 7.766 0 0 1 2.082.258 5.815 5.815 0 0 1 1.627.714" data-name="パス 42729"/>
    <path d="M113.591 0a2.488 2.488 0 0 1 1.808.73 2.413 2.413 0 0 1 .745 1.793 2.414 2.414 0 0 1-.745 1.794 2.487 2.487 0 0 1-1.808.729 2.386 2.386 0 0 1-1.778-.729 2.464 2.464 0 0 1-.715-1.794 2.463 2.463 0 0 1 .719-1.793 2.387 2.387 0 0 1 1.774-.73m2.188 20.276a.778.778 0 0 1-.79.79h-2.8a.779.779 0 0 1-.79-.79V7.357a.781.781 0 0 1 .79-.791h2.8a.78.78 0 0 1 .79.791Z" data-name="パス 42730"/>
    <path d="M128.417 16.691a.949.949 0 0 1 .562-.137.74.74 0 0 1 .562.35l1.247 1.855a.684.684 0 0 1 .122.608.927.927 0 0 1-.335.486 8.289 8.289 0 0 1-2.189 1.14 7.694 7.694 0 0 1-2.583.441 7.428 7.428 0 0 1-2.964-.593 7.719 7.719 0 0 1-4.059-3.982 7 7 0 0 1-.608-2.9 7.368 7.368 0 0 1 .593-2.933 7.519 7.519 0 0 1 4-4.013 7.26 7.26 0 0 1 2.918-.593 8.159 8.159 0 0 1 2.736.442 7.393 7.393 0 0 1 2.219 1.231.924.924 0 0 1 .289.5.708.708 0 0 1-.167.624l-1.368 1.7a.635.635 0 0 1-.547.319 1.3 1.3 0 0 1-.577-.166 4.175 4.175 0 0 0-1.079-.5 4.423 4.423 0 0 0-1.262-.167 3.338 3.338 0 0 0-2.508.988 3.534 3.534 0 0 0-.958 2.569 3.411 3.411 0 0 0 .972 2.523 3.374 3.374 0 0 0 2.493.972 4.2 4.2 0 0 0 1.307-.2 5.256 5.256 0 0 0 1.185-.562" data-name="パス 42731"/>
    <path d="M135.518 0a2.488 2.488 0 0 1 1.813.73 2.413 2.413 0 0 1 .745 1.793 2.414 2.414 0 0 1-.745 1.794 2.487 2.487 0 0 1-1.808.729 2.386 2.386 0 0 1-1.778-.729 2.464 2.464 0 0 1-.715-1.794 2.463 2.463 0 0 1 .71-1.793 2.387 2.387 0 0 1 1.778-.73m2.188 20.276a.778.778 0 0 1-.79.79h-2.8a.779.779 0 0 1-.79-.79V7.357a.781.781 0 0 1 .79-.791h2.8a.779.779 0 0 1 .79.791Z" data-name="パス 42732"/>
    <path d="M155.39 20.249a.817.817 0 0 1-.229.578.742.742 0 0 1-.562.243h-2.979a.741.741 0 0 1-.561-.243.82.82 0 0 1-.228-.578v-1.063a6.809 6.809 0 0 1-1.581 1.459 3.69 3.69 0 0 1-2.1.608 6.127 6.127 0 0 1-2.4-.5 6.38 6.38 0 0 1-2.112-1.46 7.414 7.414 0 0 1-1.5-2.341 7.985 7.985 0 0 1-.579-3.115 8.463 8.463 0 0 1 .533-3.056 7.315 7.315 0 0 1 1.424-2.361 6.53 6.53 0 0 1 2.081-1.52 5.962 5.962 0 0 1 2.524-.548 3.755 3.755 0 0 1 2.144.624 7.1 7.1 0 0 1 1.566 1.413V7.33a.778.778 0 0 1 .789-.79h2.979a.779.779 0 0 1 .791.79Zm-7.418-3.313a2.637 2.637 0 0 0 2.052-.9 3.174 3.174 0 0 0 .806-2.2 3.26 3.26 0 0 0-.806-2.265 2.637 2.637 0 0 0-2.052-.9 2.591 2.591 0 0 0-2.037.9 3.3 3.3 0 0 0-.79 2.265 3.21 3.21 0 0 0 .79 2.2 2.591 2.591 0 0 0 2.037.9" data-name="パス 42733"/>
    <path d="M158.295.79a.778.778 0 0 1 .79-.79h2.979a.763.763 0 0 1 .562.228.764.764 0 0 1 .229.562v19.456a.813.813 0 0 1-.229.577.736.736 0 0 1-.562.244h-2.979a.738.738 0 0 1-.562-.244.812.812 0 0 1-.228-.577Z" data-name="パス 42734"/>
  </g>
</svg>
)

LogoLineOA.prototype = {
  width: string,
  height: string,
}

export default LogoLineOA