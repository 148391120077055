import React from "react"

const IconConsulting = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="43.343" height="43.344" viewBox="0 0 43.343 43.344">
    <g fill="#fff" data-name="グループ 7803">
      <path d="M11.921 0a5.148 5.148 0 0 0-5.079 5.164 5.08 5.08 0 1 0 10.159 0A5.148 5.148 0 0 0 11.921 0Zm0 7.7a2.543 2.543 0 0 1-2.54-2.54 2.541 2.541 0 1 1 5.079 0 2.543 2.543 0 0 1-2.539 2.54Z" data-name="パス 17047"/>
      <path d="M39.534 0H21.587a3.814 3.814 0 0 0-3.809 3.809v8.666l-1.116-1.116a3.784 3.784 0 0 0-2.693-1.116h-7.62A6.356 6.356 0 0 0 0 16.592v7.619A3.81 3.81 0 0 0 5.079 27.8v11.734a3.808 3.808 0 0 0 6.349 2.837 3.808 3.808 0 0 0 6.349-2.837V22.725a3.824 3.824 0 0 0 2.974-.185l4.276-2.14h14.507a3.814 3.814 0 0 0 3.809-3.809V3.809A3.814 3.814 0 0 0 39.534 0ZM24.695 17.728l-5.078 2.539a1.269 1.269 0 0 1-1.467-.238l-.744-.744a1.271 1.271 0 0 0-2.168.894v19.35a1.27 1.27 0 1 1-2.54 0V26.751a1.27 1.27 0 0 0-2.54 0v12.783a1.27 1.27 0 1 1-2.54 0V16.592a1.27 1.27 0 1 0-2.54 0v7.619a1.27 1.27 0 0 1-2.54 0v-7.619a3.814 3.814 0 0 1 3.809-3.809h7.619a1.261 1.261 0 0 1 .9.372l3.784 3.785a1.27 1.27 0 0 0 1.466.238l3.443-1.721a1.27 1.27 0 0 1 1.136 2.272ZM40.8 16.592a1.271 1.271 0 0 1-1.27 1.27H27.718l.023-.065a3.8 3.8 0 0 0 .168-1.676l6.944-3.472a1.27 1.27 0 1 0-1.136-2.272l-6.944 3.472a3.806 3.806 0 0 0-4.351-.664l-2.106 1.053V3.809a1.271 1.271 0 0 1 1.27-1.27h17.948a1.271 1.271 0 0 1 1.27 1.27Z" data-name="パス 17048"/>
    </g>
  </svg>
)

export default IconConsulting