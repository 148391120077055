import { string } from "prop-types"
import React from "react"

import Badge from "../Badge/Badge"

import "./NumberList.scss"

const NumberList = props => (
  <div className="NumberList">
    <ol className="NumberList__list">
      {props.items.map((item, index) => (
        <li className="NumberList__item" key={index}>
          <Badge text={index + 1} color="white" />
          <span className="NumberList__text">{item}</span>
        </li>
      ))}
    </ol>
  </div>
)

NumberList.prototype = {
  items: Array < string > [],
}

export default NumberList
