import React, { useEffect, useState } from "react"
import { StaticImage } from "gatsby-plugin-image"

import Section from "../../components/Section/Section"
import Title from "../../components/Title/Title"
import TitleLabel from "../../components/Title/TitleLabel"

import "./Company.scss"

const Company = props => {
  /** デバイス */
  const [device, setDevice] = useState("sp")

  const scrollNavigation = id => {
    const target = document.querySelector(`#${id}`)
    const titleHeight = device === "pc" ? 110 : 60
    const targetY =
      target.getBoundingClientRect().top + window.pageYOffset - titleHeight
    window.scrollTo({ top: targetY, left: 0, behavior: "smooth" })
  }

  const changePath = () => {
    setDevice(document.body.clientWidth > 990 ? "pc" : "sp")
  }

  useEffect(() => {
    changePath()
    window.addEventListener("resize", changePath())
  }, [device])

  return (
    <Section id="Company" className="Company">
      <Title title="COMPANY" text="会社概要" />
      <div className="Company__section Company__section--divider">
        <div className="Company__sectionInner">
          <TitleLabel text="会社名" />
          <div>
            <p className="Company__sectionText">株式会社あんにん</p>
          </div>
        </div>
      </div>
      <div className="Company__section">
        <div className="Company__sectionInner">
          <TitleLabel text="事業内容" />
          <div>
            <p className="Company__sectionText">
              ウェブマーケティング事業及びウェブプロモーション事業、
              デジタルコンテンツの販売、EC (電子商取引)
              サイトの企画、制作、運営及び管理、各種出版物の企画、制作及び販売
              、各種メディアの運用及びその代行、各種コンサルティング業務
            </p>
          </div>
        </div>
      </div>
      <div className="Company__section">
        <div className="Company__sectionInner">
          <TitleLabel text="設立" />
          <div>
            <p className="Company__sectionText">2022年3月1日</p>
          </div>
        </div>
      </div>
      <div className="Company__section">
        <div className="Company__sectionInner">
          <TitleLabel text="所在地" />
          <div>
            <p className="Company__sectionText Company__address">
              <address>〒220-0005 神奈川県横浜市西区南幸2-20-15</address>
            </p>
            <p className="Company__sectionText Company__sectionText--map">
              <a href="https://goo.gl/maps/e68tHbHeGgMJFptH6" target="_blank" rel="noopener noreferrer" className="Company__link">
                <i>
                  <StaticImage
                    src="../../images/company/sp/icon-duplicate.svg"
                    alt="Google Mapへページ遷移します"
                    width={10}
                    height={10}
                    loading="lazy"
                    placeholder="none"
                  />
                </i>
                Google Map
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="Company__section">
        <div className="Company__sectionInner">
          <TitleLabel text="連絡先" />
          <div>
            <p className="Company__sectionText Company__sectionText--between">
              <a
                href="TEL:090-5783-1732"
                className="Company__link Company__link--tel"
              >
                090-5783-1732
              </a>
              <button
                className="Company__sectionButton"
                onClick={() => scrollNavigation("Contact")}
              >
                お問い合わせフォームはこちら
              </button>
            </p>
          </div>
        </div>
      </div>
      <div className="Company__section">
        <div className="Company__sectionInner">
          <TitleLabel text="代表者" />
          <div>
            <p className="Company__sectionText Company__sectionText--between">
              <span className="Company__representative">倉島 杏梨</span>
              <button
                className="Company__sectionButton"
                onClick={() => scrollNavigation("Greeding")}
              >
                代表者メッセージはこちら
              </button>
            </p>
          </div>
        </div>
      </div>
      <div className="Company__section">
        <div className="Company__sectionInner">
          <TitleLabel text="資本金" />
          <div>
            <p className="Company__sectionText">200万円</p>
          </div>
        </div>
      </div>
      <div className="Company__section Company__section--followDivider">
        <div className="Company__sectionInner">
          <TitleLabel text="社内スタッフ" />
          <div>
            <p className="Company__sectionText">５名</p>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Company
