import React from "react"
import { string } from "prop-types"

import "./Textarea.scss"

const Textarea = props => (
  <div className="Textarea">
    <textarea
      id={props.id}
      name={props.name}
      placeholder={props.placeholder}
      value={props.value}
      onChange={props.onChange}
      autocomplete={props.autocomplete}
      className="Textarea__textarea"
      maxLength={2000}
    />
  </div>
)

Textarea.prototype = {
  placeholder: string,
  name: string,
  /** labelと紐付けるFormのid */
  id: string,
}

export default Textarea
