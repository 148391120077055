import React from "react"

import "./ToggleSwitch.scss"

const ToggleSwitch = props => (
  <div className="ToggleSwitch">
    <input
      id="toggle"
      type="checkbox"
      name="terms"
      required={props.required}
      value={props.value}
      onChange={props.onChange}
    />
    <label htmlFor="toggle" />
    <span />
  </div>
)

export default ToggleSwitch
