import React from "react"

const IconAd = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="95.003" height="86.699" viewBox="0 0 95.003 86.699">
    <g fill="#fff" data-name="グループ 7801">
      <path d="M2.21 61.912a16.521 16.521 0 1 0 18.59-7.705 16.521 16.521 0 0 0-18.587 7.7Z" data-name="パス 42693"/>
      <path d="M27.012 18.956 8.426 51.172a20.651 20.651 0 0 1 28.6 16.521l8.1-14.043C29.759 27.093 27.529 24.556 27.012 18.956Z" data-name="パス 42694"/>
      <path d="M92.785 61.911C91.734 60.135 61.541 7.66 60.942 6.896a16.521 16.521 0 0 0-27.59 18.132c-.207 0-1.033-1.776 30.812 53.4a16.524 16.524 0 1 0 28.621-16.517Z" data-name="パス 42695"/>
      <path d="M2.21 61.912a16.521 16.521 0 1 0 18.59-7.705 16.521 16.521 0 0 0-18.587 7.7Z" data-name="パス 42696"/>
      <path d="M27.012 18.956 8.426 51.172a20.651 20.651 0 0 1 28.6 16.521l8.1-14.043C29.759 27.093 27.529 24.556 27.012 18.956Z" data-name="パス 42697"/>
      <path d="M92.785 61.911C91.734 60.135 61.541 7.66 60.942 6.896a16.521 16.521 0 0 0-27.59 18.132c-.207 0-1.033-1.776 30.812 53.4a16.524 16.524 0 1 0 28.621-16.517Z" data-name="パス 42698"/>
    </g>
  </svg>
)

export default IconAd