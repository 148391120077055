import { string } from "prop-types"
import React from "react"

import "./Input.scss"

const Input = props => (
  <div className="Input">
    <input
      type={props.type}
      name={props.name}
      id={props.id}
      placeholder={props.placeholder}
      className="Input__input"
      required={props.required}
      value={props.value}
      onChange={props.onChange}
      autocomplete={props.autocomplete}
    />
  </div>
)

Input.prototype = {
  type: "text" | "email" | "tel",
  placeholder: string,
  name: string,
  /** labelと紐付けるFormのid */
  id: string,
}

export default Input
