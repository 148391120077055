import React from "react"

const IconAd = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="38.927" height="35.523" viewBox="0 0 38.927 35.523">
    <g fill="#fff" data-name="グループ 7802">
      <path d="M.905 25.366a6.769 6.769 0 1 0 7.616-3.157 6.769 6.769 0 0 0-7.616 3.157Z" data-name="パス 17080"/>
      <path d="m11.073 7.766-7.62 13.2a8.462 8.462 0 0 1 11.72 6.77l3.317-5.754C12.194 11.1 11.28 10.059 11.073 7.766Z" data-name="パス 17081"/>
      <path d="M38.019 25.367c-.432-.728-12.8-22.229-13.048-22.542a6.769 6.769 0 0 0-11.3 7.429c-.085 0-.423-.728 12.625 21.882a6.771 6.771 0 1 0 11.728-6.769Z" data-name="パス 17082"/>
      <path d="M.905 25.366a6.769 6.769 0 1 0 7.616-3.157 6.769 6.769 0 0 0-7.616 3.157Z" data-name="パス 17084"/>
      <path d="m11.073 7.766-7.62 13.2a8.462 8.462 0 0 1 11.72 6.77l3.317-5.754C12.194 11.1 11.28 10.059 11.073 7.766Z" data-name="パス 17085"/>
      <path d="M38.019 25.367c-.432-.728-12.8-22.229-13.048-22.542a6.769 6.769 0 0 0-11.3 7.429c-.085 0-.423-.728 12.625 21.882a6.771 6.771 0 1 0 11.728-6.769Z" data-name="パス 17086"/>
    </g>
  </svg>
)

export default IconAd