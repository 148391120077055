import React, { useEffect, useState } from "react"

import TitleList from "../../components/Title/TitleList"
import Section from "../../components/Section/Section"
import Card from "../../components/Card/Card"
import Radio from "../../components/Form/Radio"
import FormLabel from "../../components/Form/FormLabel"
import LineOA from "../../components/LineOA/LineOA"

import Corporation from "./Corporation"
import Instagram from "./Instagram"

import "./Contact.scss"

const Contact = props => {
  /** Tabの表示するコンテンツ */
  const [currentPanel, setCurrentPanel] = useState("0")
  /** デバイス */
  const [device, setDevice] = useState("sp")

  const changeTab = value => {
    setCurrentPanel(value)
  }

  const changePath = () => {
    setDevice(document.body.clientWidth > 990 ? "pc" : "sp")
  }

  useEffect(() => {
    changePath()

    window.addEventListener("resize", changePath())
  }, [device])

  return (
    <Section id="Contact" className="Contact">
      <Card addClassName="Contact__card">
        <TitleList title="CONTACT" text="お問い合わせ" position="center" />

        <div className="Tab Contact__tab">
          <div className="Tab__buttonWrapper">
            {device === "sp" ? (
              <React.Fragment>
                <button
                  className={`Tab__button${
                    currentPanel === "0" ? " Tab__button--current" : ""
                  }`}
                  onClick={() => changeTab("0")}
                >
                  <span className="Tab__buttonText">企業様はこちら</span>
                </button>
                <button
                  className={`Tab__button${
                    currentPanel === "1" ? " Tab__button--current" : ""
                  }`}
                  onClick={() => changeTab("1")}
                >
                  <span className="Tab__buttonText">
                    インスタグラマーの方はこちら
                  </span>
                </button>
              </React.Fragment>
            ) : (
              <div className="Contact__formItem">
                <FormLabel title="お客様" label="必須" />
                <Radio
                  name="tab"
                  id="company"
                  value="0"
                  checked={currentPanel === "0"}
                  onChange={() => changeTab("0")}
                >
                  企業様はこちら
                </Radio>
                <Radio
                  name="tab"
                  id="instagram"
                  value="1"
                  checked={currentPanel === "1"}
                  onChange={() => changeTab("1")}
                >
                  インスタグラマーの方はこちら
                </Radio>
              </div>
            )}
          </div>
          {currentPanel === "0" && <Corporation />}
          {currentPanel === "1" && <Instagram />}
        </div>
      </Card>
      <LineOA />
    </Section>
  )
}

export default Contact
