import React, { useEffect, useState } from "react"

import "./ScrollBar.scss"

const ScrollBar = props => {
  /** デバイス */
  const [device, setDevice] = useState("sp")

  const changePath = () => {
    setDevice(document.body.clientWidth > 990 ? "pc" : "sp")
  }

  useEffect(() => {
    changePath()
    window.addEventListener("resize", changePath())
  }, [device])

  return (
    <div className={`ScrollBar ScrollBar--${device}`}>
      <span className="ScrollBar__text">SCROLL</span>
      <span className="ScrollBar__icon" />
    </div>
  )
}

export default ScrollBar
