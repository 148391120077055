import { string } from "prop-types"
import React from "react"

import "./TitleList.scss"

const TitleList = props => (
  <div
    className={`TitleList${
      props.position ? ` TitleList--${props.position}` : ""
    }`}
  >
    <h2 className="TitleList__title">{props.title}</h2>
    {props.text && <span className="TitleList__text">{props.text}</span>}
  </div>
)

TitleList.prototype = {
  title: string,
  text: string,
  position: string,
}

export default TitleList
