import { string } from "prop-types"
import React from "react"

const IconInstagram = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 41.672 41.672">
    <path fill="#fff" d="M21.247 41.673h-.415c-3.267.008-6.285-.075-9.22-.254a12.325 12.325 0 0 1-7.1-2.689 11.4 11.4 0 0 1-3.836-6.187 27.413 27.413 0 0 1-.629-6.545C.026 24.476 0 22.673 0 20.84s.026-3.642.047-5.165a27.416 27.416 0 0 1 .629-6.546 11.4 11.4 0 0 1 3.832-6.186 12.325 12.325 0 0 1 7.1-2.689c2.939-.179 5.958-.262 9.232-.256s6.285.075 9.22.254a12.325 12.325 0 0 1 7.1 2.689A11.4 11.4 0 0 1 41 9.129a27.414 27.414 0 0 1 .629 6.546c.021 1.521.043 3.324.047 5.157v.008c0 1.833-.026 3.636-.047 5.157A27.4 27.4 0 0 1 41 32.543a11.4 11.4 0 0 1-3.832 6.186 12.325 12.325 0 0 1-7.1 2.689c-2.819.171-5.707.255-8.821.255Zm-.415-3.256c3.213.008 6.164-.073 9.03-.248a8.968 8.968 0 0 0 5.244-1.963 8.22 8.22 0 0 0 2.735-4.468 24.94 24.94 0 0 0 .528-5.785c.021-1.511.042-3.3.047-5.117s-.026-3.605-.047-5.117a24.944 24.944 0 0 0-.528-5.786 8.22 8.22 0 0 0-2.735-4.468 8.97 8.97 0 0 0-5.244-1.962c-2.862-.174-5.817-.255-9.022-.248s-6.164.073-9.03.248a8.97 8.97 0 0 0-5.245 1.963 8.22 8.22 0 0 0-2.734 4.468 24.942 24.942 0 0 0-.531 5.785c-.021 1.512-.042 3.3-.047 5.12s.026 3.6.047 5.113a24.94 24.94 0 0 0 .528 5.785 8.22 8.22 0 0 0 2.735 4.468 8.97 8.97 0 0 0 5.247 1.963c2.866.175 5.817.256 9.022.248Zm-.078-7.406a10.174 10.174 0 1 1 10.174-10.175A10.186 10.186 0 0 1 20.754 31.01Zm0-17.093a6.918 6.918 0 1 0 6.918 6.918 6.926 6.926 0 0 0-6.918-6.918Zm11.314-6.512a2.442 2.442 0 1 0 2.442 2.442 2.442 2.442 0 0 0-2.442-2.442Zm0 0"/>
  </svg>
)

IconInstagram.prototype = {
  width: string,
  height: string,
}

export default IconInstagram