import React, { useEffect, useState } from "react"

import LogoAnin from "../../images/header/logo-anin"

import "./FadeIn.scss"

const FadeIn = props => {
  /** デバイス */
  const [device, setDevice] = useState("sp")
  /** 表示管理 */
  const [isShow, setIsShow] = useState(false)

  const changePath = () => {
    setDevice(document.body.clientWidth > 990 ? "pc" : "sp")
  }

  useEffect(() => {
    changePath()
    window.addEventListener("resize", changePath())
    document.body.classList.add("FadeIn--stoppable")
  }, [device])

  // useEffect(() => {
  setTimeout(() => {
    setIsShow(true)
    document.body.classList.remove("FadeIn--stoppable")
  }, 1600)
  // })

  return (
    <>
      <div className={`FadeIn${isShow ? " FadeIn--out" : ""}`}>
        <LogoAnin width="162" height="32.39" />
      </div>
    </>
  )
}

export default FadeIn
