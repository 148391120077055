import { string } from "prop-types"
import React from "react"

const LogoInstagram = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 272 272.01">
    <path fill="#fff" d="M138.682 272.01h-2.708c-21.321.052-41.022-.49-60.181-1.656-17.565-1.069-33.6-7.139-46.37-17.553C17.1 242.749 8.685 229.162 4.412 212.419.693 197.842.5 183.533.307 169.693.17 159.763.029 147.999 0 136.03c.029-12.016.17-23.783.307-33.713C.5 88.479.693 74.17 4.412 59.591 8.685 42.848 17.1 29.261 29.423 19.213 42.194 8.799 58.228 2.729 75.8 1.66 94.954.499 114.659-.048 136.026-.001c21.328-.046 41.022.492 60.181 1.656 17.565 1.069 33.6 7.139 46.37 17.553 12.325 10.048 20.738 23.635 25.011 40.379 3.719 14.577 3.916 28.888 4.1 42.726.137 9.93.28 21.7.307 33.663v.05c-.027 11.966-.17 23.733-.307 33.663-.189 13.838-.384 28.147-4.1 42.726-4.273 16.743-12.686 30.33-25.011 40.379-12.771 10.414-28.8 16.484-46.37 17.553-18.348 1.119-37.195 1.663-57.525 1.663Zm-2.708-21.255c20.975.05 40.233-.479 58.942-1.617 13.282-.807 24.8-5.118 34.232-12.811 8.72-7.112 14.726-16.924 17.849-29.164 3.1-12.134 3.275-25.163 3.447-37.762.135-9.864.276-21.548.305-33.4-.029-11.852-.17-23.534-.305-33.4-.172-12.6-.351-25.628-3.447-37.764-3.123-12.24-9.129-22.052-17.849-29.164-9.434-7.691-20.95-12-34.232-12.809-18.709-1.139-37.967-1.664-58.892-1.619-20.971-.05-40.231.479-58.94 1.619-13.282.807-24.8 5.118-34.232 12.809C34.132 42.792 28.126 52.599 25 64.844c-3.1 12.136-3.275 25.163-3.447 37.764-.135 9.872-.276 21.562-.305 33.422.029 11.808.17 23.5.305 33.372.172 12.6.351 25.628 3.447 37.762 3.123 12.24 9.129 22.052 17.849 29.164 9.434 7.691 20.95 12 34.232 12.809 18.712 1.139 37.976 1.67 58.893 1.618Zm-.506-48.341a66.409 66.409 0 1 1 66.409-66.409 66.485 66.485 0 0 1-66.409 66.409Zm0-111.567a45.158 45.158 0 1 0 45.158 45.158 45.21 45.21 0 0 0-45.158-45.158Zm73.847-42.5a15.938 15.938 0 1 0 15.938 15.938 15.938 15.938 0 0 0-15.939-15.94Zm0 0" opacity=".091"/>
  </svg>
)

LogoInstagram.prototype = {
  width: string,
  height: string,
}

export default LogoInstagram