import { string } from "prop-types"
import React from "react"

const LogoInstagram = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} viewBox="0 0 17.023 17.023">
    <path fill="#fff" d="M8.68 17.023h-.17c-1.334 0-2.567-.031-3.766-.1a5.035 5.035 0 0 1-2.9-1.1 4.658 4.658 0 0 1-1.568-2.525 11.2 11.2 0 0 1-.257-2.674C.011 9.998 0 9.262 0 8.513s.011-1.488.019-2.11a11.2 11.2 0 0 1 .257-2.674 4.658 4.658 0 0 1 1.565-2.531 5.035 5.035 0 0 1 2.9-1.1c1.202-.068 2.435-.1 3.772-.1s2.567.031 3.766.1a5.035 5.035 0 0 1 2.9 1.1 4.658 4.658 0 0 1 1.565 2.527A11.2 11.2 0 0 1 17 6.403c.009.621.018 1.358.019 2.107 0 .749-.011 1.485-.019 2.107a11.193 11.193 0 0 1-.257 2.674 4.658 4.658 0 0 1-1.565 2.527 5.035 5.035 0 0 1-2.9 1.1 57.857 57.857 0 0 1-3.598.105Zm-.17-1.325c1.313 0 2.518-.03 3.689-.1a3.664 3.664 0 0 0 2.142-.8 3.358 3.358 0 0 0 1.117-1.825 10.188 10.188 0 0 0 .216-2.363c.008-.617.017-1.349.019-2.09s-.011-1.473-.019-2.09a10.19 10.19 0 0 0-.216-2.363 3.358 3.358 0 0 0-1.117-1.825 3.664 3.664 0 0 0-2.142-.8c-1.171-.071-2.376-.1-3.686-.1s-2.518.03-3.689.1a3.664 3.664 0 0 0-2.142.8 3.358 3.358 0 0 0-1.117 1.816 10.189 10.189 0 0 0-.216 2.363c-.008.618-.017 1.349-.019 2.092s.011 1.471.019 2.089a10.188 10.188 0 0 0 .216 2.363 3.358 3.358 0 0 0 1.117 1.825 3.664 3.664 0 0 0 2.142.8 58.92 58.92 0 0 0 3.686.108Zm-.032-3.03a4.156 4.156 0 1 1 4.156-4.156 4.161 4.161 0 0 1-4.156 4.156Zm0-6.983A2.826 2.826 0 1 0 11.3 8.511a2.83 2.83 0 0 0-2.822-2.826Zm4.622-2.66a1 1 0 1 0 1 1 1 1 0 0 0-1-1Zm0 0" opacity=".999"/>
  </svg>
)

LogoInstagram.prototype = {
  width: string,
  height: string,
}

export default LogoInstagram