import { string } from "prop-types"
import React from "react"

import "./Strengths.scss"

const Strengths = props => (
  <div className="Strengths">
    <div className="Strengths__inner">
      <div className="Strengths__title">{props.title}</div>
      {props.items.map((item, index) => (
        <div className="Strengths__item" key={index}>
          {item}
        </div>
      ))}
    </div>
  </div>
)

Strengths.prototype = {
  title: string,
  items: Array < string > [],
}

export default Strengths
