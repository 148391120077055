import React from "react"

import "./Radio.scss"

const Radio = props => (
  <div className="Radio">
    <input
      type="Radio"
      name={props.name}
      id={props.id}
      value={props.value}
      checked={props.checked}
      onChange={props.onChange}
    />
    <label htmlFor={props.id} className="Radio__text">
      <span className="Radio__icon" />
      {props.children}
    </label>
  </div>
)

export default Radio
